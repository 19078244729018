import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { commonService } from "../../../../utils/commonService";
import { API_URL } from "../../../../utils/apiRoutes";
import { apiCall } from "../../../../utils/axiosService";
import { apiResponse } from "../../../../utils/apiResponse";
import { Button, Form, Overlay, Popover, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Loader from "../../../Loader/Loader";
import moment from "moment";
import { errorToastMessage } from "../../../../utils/toastMessage";
import { useSelector } from "react-redux";
import downArrow from "../../../../Assets/Images/Header/downArrow.svg";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import darkNoteIcon from "../../../../Assets/Images/CRM/darkNotes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import { compName } from "../../../../utils/constant";
import WidgetNote from "../../WidgetNote";
import emailIcon from "../../../../Assets/Images/Email.svg";
import WidgetDetailModal from "../../WidgetDetailModal";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import Comments from "../Comments/Comment";
import { FixColumn } from "../../../../utils/dataTable";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import EventViewModal from "../../../CommonComponent/EventViewModal";
import ToolsSummaryModal from "../../ToolsSummaryModal";

const NinentyDayChild = ({ api, para }) => {
  const userDetail = commonService.getLoginUserData();
  let leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    adr = 0,
    rev = 0,
    oneRms = 0,
    oneAdr = 0,
    oneRev = 0,
    sevenRms = 0,
    sevenAdr = 0,
    sevenRev = 0,
    dayName = false,
    weekdayLeftToSell = 0,
    weekdayOtb = 0,
    weekdayOccPercentage = 0,
    weekdayAdr = 0,
    weekdayRev = 0,
    weekdayoneRms = 0,
    weekdayoneAdr = 0,
    weekdayoneRev = 0,
    weekdaysevenRms = 0,
    weekdaysevenAdr = 0,
    weekdaysevenRev = 0,
    weekendLeftToSell = 0,
    weekendOtb = 0,
    weekendOccPercentage = 0,
    weekendAdr = 0,
    weekendRev = 0,
    weekendoneRms = 0,
    weekendoneAdr = 0,
    weekendoneRev = 0,
    weekendsevenRms = 0,
    weekendsevenAdr = 0,
    weekendsevenRev = 0,
    sunLeftToSell = 0,
    sunOtb = 0,
    sunOccPercentage = 0,
    sunAdr = 0,
    sunRev = 0,
    sunoneRms = 0,
    sunoneAdr = 0,
    sunoneRev = 0,
    sunsevenRms = 0,
    sunsevenAdr = 0,
    sunsevenRev = 0,
    monLeftToSell = 0,
    monOtb = 0,
    monOccPercentage = 0,
    monAdr = 0,
    monRev = 0,
    mononeRms = 0,
    mononeAdr = 0,
    mononeRev = 0,
    monsevenRms = 0,
    monsevenAdr = 0,
    monsevenRev = 0,
    tueLeftToSell = 0,
    tueOtb = 0,
    tueOccPercentage = 0,
    tueAdr = 0,
    tueRev = 0,
    tueoneRms = 0,
    tueoneAdr = 0,
    tueoneRev = 0,
    tuesevenRms = 0,
    tuesevenAdr = 0,
    tuesevenRev = 0,
    wedLeftToSell = 0,
    wedOtb = 0,
    wedOccPercentage = 0,
    wedAdr = 0,
    wedRev = 0,
    wedoneRms = 0,
    wedoneAdr = 0,
    wedoneRev = 0,
    wedsevenRms = 0,
    wedsevenAdr = 0,
    wedsevenRev = 0,
    thuLeftToSell = 0,
    thuOtb = 0,
    thuOccPercentage = 0,
    thuAdr = 0,
    thuRev = 0,
    thuoneRms = 0,
    thuoneAdr = 0,
    thuoneRev = 0,
    thusevenRms = 0,
    thusevenAdr = 0,
    thusevenRev = 0,
    friLeftToSell = 0,
    friOtb = 0,
    friOccPercentage = 0,
    friAdr = 0,
    friRev = 0,
    frioneRms = 0,
    frioneAdr = 0,
    frioneRev = 0,
    frisevenRms = 0,
    frisevenAdr = 0,
    frisevenRev = 0,
    satLeftToSell = 0,
    satOtb = 0,
    satOccPercentage = 0,
    satAdr = 0,
    satRev = 0,
    satoneRms = 0,
    satoneAdr = 0,
    satoneRev = 0,
    satsevenRms = 0,
    satsevenAdr = 0,
    satsevenRev = 0,
    totalWeekendDays = 0,
    totalWeekDays = 0,
    montotalDay = 0,
    tuetotalDay = 0,
    wedtotalDay = 0,
    thutotalDay = 0,
    fritotalDay = 0,
    sattotalDay = 0,
    suntotalDay = 0,
    lytotalRms = 0,
    weekdaylyRms = 0,
    weekendlyRms = 0,
    sunlyRms = 0,
    monlyRms = 0,
    tuelyRms = 0,
    wedlyRms = 0,
    thulyRms = 0,
    frilyRms = 0,
    satlyRms = 0,
    lytotallyAdr = 0,
    weekdaylyAdr = 0,
    weekendlyAdr = 0,
    sunlyAdr = 0,
    monlyAdr = 0,
    tuelyAdr = 0,
    wedlyAdr = 0,
    thulyAdr = 0,
    frilyAdr = 0,
    satlyAdr = 0,
    lytotallyRev = 0,
    weekdaylyRev = 0,
    weekendlyRev = 0,
    sunlyRev = 0,
    monlyRev = 0,
    tuelyRev = 0,
    wedlyRev = 0,
    thulyRev = 0,
    frilyRev = 0,
    satlyRev = 0,
    netStlyTotalRms = 0,
    weekdayNetstlyRms = 0,
    weekendNetstlyRms = 0,
    sunNetstlyRms = 0,
    monNetstlyRms = 0,
    tueNetstlyRms = 0,
    wedNetstlyRms = 0,
    thuNetstlyRms = 0,
    friNetstlyRms = 0,
    satNetstlyRms = 0,
    netStlyTotalAdr = 0,
    weekdayNetstlyAdr = 0,
    weekendNetstlyAdr = 0,
    sunNetstlyAdr = 0,
    monNetstlyAdr = 0,
    tueNetstlyAdr = 0,
    wedNetstlyAdr = 0,
    thuNetstlyAdr = 0,
    friNetstlyAdr = 0,
    satNetstlyAdr = 0,
    netStlyTotalRev = 0,
    weekdayNetstlyRev = 0,
    weekendNetstlyRev = 0,
    sunNetstlyRev = 0,
    monNetstlyRev = 0,
    tueNetstlyRev = 0,
    wedNetstlyRev = 0,
    thuNetstlyRev = 0,
    friNetstlyRev = 0,
    satNetstlyRev = 0,
    onedayAdrCount = 0,
    weekdayOneDayAdrCount = 0,
    weekendOneDayAdrCount = 0,
    sunoneDayAdrCount = 0,
    mononeDayAdrCount = 0,
    tueoneDayAdrCount = 0,
    wedoneDayAdrCount = 0,
    thuoneDayAdrCount = 0,
    frioneDayAdrCount = 0,
    satoneDayAdrCount = 0,
    sevendayAdrCount = 0,
    weekdaysevenDayAdrCount = 0,
    weekendsevenDayAdrCount = 0,
    sunsevenDayAdrCount = 0,
    monsevenDayAdrCount = 0,
    tuesevenDayAdrCount = 0,
    wedsevenDayAdrCount = 0,
    thusevenDayAdrCount = 0,
    frisevenDayAdrCount = 0,
    satsevenDayAdrCount = 0;

  const date = commonService.convertDateInTimezone(new Date());
  date.setMonth(date.getMonth() + 2);
  const startDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth(), 1)
    )
  );
  const endDate = commonService.getDateInDBFormat(
    commonService.convertDateInTimezone(
      new Date(date.getFullYear(), date.getMonth() + 1, 0)
    )
  );

  let rsd = moment(startDate).toDate();
  rsd.setFullYear(rsd.getFullYear() - 1);
  let rightStartDate = commonService.getDateInFormat(
    commonService.convertDateInTimezone(rsd)
  );

  let red = moment(endDate).toDate();
  red.setFullYear(red.getFullYear() - 1);
  let rightEndDate = commonService.getDateInFormat(
    commonService.convertDateInTimezone(red)
  );

  // let rightStartDate = new Date(startDate).setFullYear(
  //   new Date(startDate).getFullYear() - 1
  // );

  // let rightEndDate = new Date(endDate).setFullYear(
  //   new Date(endDate).getFullYear() - 1
  // );

  const startDateArrayRef = useRef([]);
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const dateForNoteRef = useRef();
  const userNoteRef = useRef();
  const maxDateRef = useRef();
  const sevenMaxDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const singleDateSevenPickupDateRef = useRef();
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();

  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [selectSevenPickupDate, setSelectSevenPickupDate] = useState();
  const [pickupFromData, setPickupFromData] = useState(null);
  const [sevenPickupFromData, setSevenPickupFromData] = useState(null);
  const [pickupLoading, setPickupLoading] = useState(false);
  const [sevenPickupLoading, setSevenPickupLoading] = useState(false);
  const [forecastData, setForecastData] = useState(null);
  const [forecastLoading, setForecastLoading] = useState(null);
  const [rateShopListData, setRateShopListData] = useState(null);
  const [rateShopLoading, setRateShopLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const callApi = useRef(false);
  const isFilterApiCalled = useRef(false);
  const [segmentDrilldownData, setSegmentDrilldownData] = useState(null);
  const [dropDownLoading, setDropDownLLoading] = useState(false);
  const rateCodeDataRef = useRef(false);
  const companyDataRef = useRef(false);
  const guestDataRef = useRef(false);
  const totalRef = useRef();
  const clickedTrRef = useRef();
  const [lyData, setLyData] = useState(null);
  const [lyLoading, setLyLoading] = useState(false);
  const [netStlyData, setNetStlyData] = useState(null);
  const [netstlyLoading, setNetstlyLoading] = useState(false);
  const [totalLoading, setTotalLoading] = useState(false);
  const [totalData, setTotalData] = useState(null);
  const selectedMarketSegmentRef = useRef();
  const selectedRateCodeRef = useRef();
  const selectedCompanyRef = useRef();
  const calledMarketSegmentRef = useRef([]);
  const [msLoading, setMsLoading] = useState(false);
  const calledRateCodeRef = useRef([]);
  const [rateCodeLoading, setRateCodeLoading] = useState(false);
  const calledCompanyRef = useRef([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [showToolsSummaryModal, setToolsSummaryModal] = useState(false);
  const [ClickedDate, setClickedDate] = useState(new Date());

  const closeToolsSummaryModal = () => {
    setToolsSummaryModal(false);
  };
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const closeTicketModal = () => {
    setShowTicketModal(false);
  };
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();

  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();
  const totalRateShopRef = useRef();
  const clickedDateForNote = useRef();
  const [noteDetail, setNoteDetail] = useState(null);
  const htmlToText = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    const plainText = tempElement.innerText;
    return plainText;
  };

  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);

    let sevenDate = new Date(maxDateForPickupFrom);
    sevenMaxDateRef.current = sevenDate.setDate(sevenDate.getDate() - 7);
  }

  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const closeNoteModal = () => {
    setAddNoteModal(false);
  };

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      // let datesArr = commonService.getDateBetweenTwoDates(event[0], event[1]);
      // startDateArrayRef.current = datesArr;
      // //call api when date changes
      // getORGDetail();
      // getPickupFromData();
      // getSevenPickupFromData();
      // getForecastDetail();
      // rateShopListAsOfDate();
      // getLyDetail();
      // getNetStlyDetail();
      // getNinentyDayTotal();
    }
  };

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setLoading(true);
    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      // startDateArrayRef.current = [];
      // let orgData = response?.data?.data?.map((item, index) => {
      //   let dateForTd = startRangeDate
      //     ? new Date(startRangeDate)
      //     : new Date(startDate);

      //   startDateArrayRef.current = [
      //     ...startDateArrayRef.current,
      //     commonService.getDateInDBFormat(
      //       dateForTd.setDate(dateForTd.getDate() + index)
      //     ),
      //   ];
      //   return item;
      // });
      setOrgData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const getNoteByDataList = async () => {
    let noteByDataListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.Configuration +
          API_URL.WIDGET_NOTE.GET_BY_DATA_LIST,
        body: {
          propertyid: propertyDetailFromRTK?.propertyid,
          // widgetid: para?.widgetId,
          widgetid: 0,
          startdate: startRangeDateRef.current
            ? commonService.getDateInDBFormat(startRangeDateRef.current)
            : commonService.getDateInDBFormat(startDate),
          enddate: endRangeDateRef.current
            ? commonService.getDateInDBFormat(endRangeDateRef.current)
            : commonService.getDateInDBFormat(endDate),
        },
      },
      false
    );

    let response = apiResponse(false, noteByDataListResponse);

    if (response?.isValidate) {
      setNoteDetail(response?.data?.data);
    }

    if (!response?.isValidate) {
      setNoteDetail(null);
    }
  };

  const getPickupFromData = async () => {
    setPickupLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    singleDatePickupDateRef.current = pickupDate;
    let pickupFromDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.PICKUP_FROM,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          pickupDate: selectPickupDate
            ? moment(selectPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, pickupFromDataResponse);

    if (response?.isValidate) {
      let pickupFromData = response?.data?.data?.map((item, index) => {
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });

      setPickupFromData(pickupFromData);
      setPickupLoading(false);
    }

    if (!response?.isValidate) {
      setPickupLoading(false);
    }
  };

  const getSevenPickupFromData = async () => {
    setSevenPickupLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 7);
    singleDateSevenPickupDateRef.current = pickupDate;
    let pickupFromDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.PICKUP_FROM,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          pickupDate: selectSevenPickupDate
            ? moment(selectSevenPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, pickupFromDataResponse);

    if (response?.isValidate) {
      let pickupFromData = response?.data?.data?.map((item, index) => {
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });

      setSevenPickupFromData(pickupFromData);
      setSevenPickupLoading(false);
    }

    if (!response?.isValidate) {
      setSevenPickupLoading(false);
    }
  };

  const getForecastDetail = async () => {
    setForecastLoading(true);
    let forecastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.PRICING_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          ForcastType: "EMA",
        },
      },
      false
    );

    let response = apiResponse(false, forecastResponse);

    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setForecastLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData([]);
      setForecastLoading(false);
    }
  };

  const rateShopListAsOfDate = async () => {
    setRateShopLoading(true);

    let byPropertyIdResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.RevenueManagement +
          API_URL.REVENUE_RATE_SHOP_CONFIG.GET_BY_PROPERTY_ID,
        body: {
          id: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let propertyResponse = apiResponse(false, byPropertyIdResponse);

    let rateShopListResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.RATESHOP_LIST,
        body: {
          AsOfDate: para?.asOfDate,
          propertyCode: para?.propertyCode,
          Channel: propertyResponse?.data?.data?.channel_term
            ? propertyResponse?.data?.data?.channel_term
            : "",
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, rateShopListResponse);
    if (response?.isValidate) {
      totalRateShopRef.current = [];
      let rateShopData = response?.data?.data?.map((item, index) => {
        let findIndex = totalRateShopRef.current?.findIndex(
          (x) => x === item?.competiterpropertyname
        );
        if (findIndex < 0) {
          totalRateShopRef.current = [
            ...totalRateShopRef.current,
            item?.competiterpropertyname,
          ];
        }
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });
      setRateShopListData(rateShopData);
      setRateShopLoading(false);
    }

    if (!response?.isValidate) {
      totalRateShopRef.current = [];
      setRateShopListData(null);
      setRateShopLoading(false);
    }
  };

  const saveNote = async () => {
    let saveResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.SAVE_UPDATE,
        body: {
          propertyCode: para?.propertyCode,
          Dates: dateForNoteRef.current,
          UserNotes: userNoteRef.current,
        },
      },
      false
    );

    let response = apiResponse(true, saveResponse, setNoteLoading);

    if (response?.isValidate) {
    }
    if (!response?.isValidate) {
      errorToastMessage(response?.data?.message);
    }
  };

  const getLyDetail = async () => {
    setLyLoading(true);
    let startDatePayload = "",
      endDatePayload = "";
    if (startRangeDateRef.current) {
      let sd = moment(startRangeDateRef.current).toDate();
      sd.setFullYear(sd.getFullYear() - 1);
      startDatePayload = commonService.convertDateInTimezone(sd);
    } else {
      let sd = moment(startDate).toDate();
      sd.setFullYear(sd.getFullYear() - 1);
      startDatePayload = commonService.convertDateInTimezone(sd);
    }

    if (endRangeDateRef.current) {
      let ed = moment(endRangeDateRef.current).toDate();
      ed.setFullYear(ed.getFullYear() - 1);
      endDatePayload = commonService.convertDateInTimezone(ed);
    } else {
      let ed = moment(endDate).toDate();
      ed.setFullYear(ed.getFullYear() - 1);
      endDatePayload = commonService.convertDateInTimezone(ed);
    }

    let lyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot + API_URL.REV_PAK_90_DAY_OUTLOOK.GET_LY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? commonService.getDateForNode(
                startDatePayload
                // new Date(startRangeDateRef.current).setFullYear(
                //   new Date(startRangeDateRef.current).getFullYear() - 1
                // )
              )
            : commonService.getDateForNode(
                startDatePayload
                // new Date(startDate).setFullYear(
                //   new Date(startDate).getFullYear() - 1
                // )
              ),
          endDate: endRangeDateRef.current
            ? commonService.getDateForNode(
                endDatePayload
                // new Date(endRangeDateRef.current).setFullYear(
                //   new Date(endRangeDateRef.current).getFullYear() - 1
                // )
              )
            : commonService.getDateForNode(
                endDatePayload
                // new Date(endDate).setFullYear(
                //   new Date(endDate).getFullYear() - 1
                // )
              ),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, lyDataResponse);

    if (response?.isValidate) {
      setLyData(response?.data?.data);
      setLyLoading(false);
    }

    if (!response?.isValidate) {
      setLyLoading(false);
    }
  };

  const getNetStlyDetail = async () => {
    setNetstlyLoading(true);
    let startDatePayload = "",
      endDatePayload = "";
    if (startRangeDateRef.current) {
      let sd = moment(startRangeDateRef.current).toDate();
      sd.setFullYear(sd.getFullYear() - 1);
      startDatePayload = commonService.convertDateInTimezone(sd);
    } else {
      let sd = moment(startDate).toDate();
      sd.setFullYear(sd.getFullYear() - 1);
      startDatePayload = commonService.convertDateInTimezone(sd);
    }

    if (endRangeDateRef.current) {
      let ed = moment(endRangeDateRef.current).toDate();
      ed.setFullYear(ed.getFullYear() - 1);
      endDatePayload = commonService.convertDateInTimezone(ed);
    } else {
      let ed = moment(endDate).toDate();
      ed.setFullYear(ed.getFullYear() - 1);
      endDatePayload = commonService.convertDateInTimezone(ed);
    }

    let lyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_NET_STLY,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? commonService.getDateForNode(
                startDatePayload
                // new Date(startRangeDateRef.current).setFullYear(
                //   new Date(startRangeDateRef.current).getFullYear() - 1
                // )
              )
            : commonService.getDateForNode(
                startDatePayload
                // new Date(startDate).setFullYear(
                //   new Date(startDate).getFullYear() - 1
                // )
              ),
          endDate: endRangeDateRef.current
            ? commonService.getDateForNode(
                endDatePayload
                // new Date(endRangeDateRef.current).setFullYear(
                //   new Date(endRangeDateRef.current).getFullYear() - 1
                // )
              )
            : commonService.getDateForNode(
                endDatePayload
                // new Date(endDate).setFullYear(
                //   new Date(endDate).getFullYear() - 1
                // )
              ),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, lyDataResponse);

    if (response?.isValidate) {
      setNetStlyData(response?.data?.data);
      setNetstlyLoading(false);
    }

    if (!response?.isValidate) {
      setNetstlyLoading(false);
    }
  };

  const showOnApiCall = (idArray) => {
    for (let i = 0; i < idArray?.length; i++) {
      const classList = document.getElementsByClassName(idArray[i]);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
          classList[i].classList.add("singleDateSummmaryTr");
        }
      }
    }
  };

  const handleShowHideRow = (className, itemDetail) => {
    if (document.getElementById(className) !== null) {
      if (document.getElementById(className).classList.contains("active")) {
        document.getElementById(className).classList.remove("active");
      } else {
        document.getElementById(className).classList.add("active");
      }
    }

    const removeClass = (classNameDetail) => {
      const classList = document.getElementsByClassName(classNameDetail);
      for (let i = 0; i < classList?.length; i++) {
        if (classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.remove("nestedTableRowNone");
          classList[i].classList.add("singleDateSummmaryTr");
        } else {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    removeClass(className);

    const addChildClass = (childClassName) => {
      const classList = document.getElementsByClassName(childClassName);
      for (let i = 0; i < classList?.length; i++) {
        if (!classList[i].classList.contains("nestedTableRowNone")) {
          classList[i].classList.add("nestedTableRowNone");
          classList[i].classList.remove("singleDateSummmaryTr");
        }
      }
    };

    const removeAllClass = (childItems) => {
      for (let i = 0; i < childItems?.length; i++) {
        addChildClass(childItems[i]?.guid);

        if (
          Array.isArray(childItems[i]?.items) &&
          childItems[i]?.items?.length > 0
        ) {
          removeAllClass(childItems[i]?.items);
        }
      }
    };

    if (Array.isArray(itemDetail?.items) && itemDetail?.items?.length > 0) {
      removeAllClass(itemDetail?.items);
    }
  };

  const checkActiveRow = (e, guid, itemDetail) => {
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
      e.currentTarget.classList.remove("singleDateSummmaryTr");
    } else {
      e.currentTarget.classList.add("active");
      e.currentTarget.classList.add("singleDateSummmaryTr");
    }
  };

  const getMarketSegmentData = async () => {
    setDropDownLLoading(true);
    let marketSegmentResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_MARKETSEGMENT,
        body: {
          leftAsOfDate: para?.asOfDate,
          leftpropertyCode: para?.propertyCode,
          leftstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(startDate)
          ),
          leftendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(endDate)
          ),
          rightAsOfDate: para?.asOfDate,
          rightpropertyCode: para?.propertyCode,
          rightstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightStartDate)
          ),
          rightendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightEndDate)
          ),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, marketSegmentResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;
      let total = {
        leftRoom: 0,
        leftAdr: 0,
        leftRev: 0,
        leftRevContri: 100,
        rightRoom: 0,
        rightAdr: 0,
        rightRev: 0,
        rightRevContri: 100,
      };

      let groupingData = dataFromApi?.map((item) => {
        item.items = [];
        item.guid = commonService.GenerateGUID();
        item.name = item?.marketsegment;
        // if(item?.left_RMS !==null && item?.right_RMS!==null){
        //   item.varientRms = parseInt(item?.left_RMS)  - parseInt(item?.right_RMS)
        // }
        item.varientRms =
          (item.left_RMS !== null ? parseInt(item?.left_RMS) : 0) -
          (item.right_RMS !== null ? parseInt(item?.right_RMS) : 0);
        item.varientAdr =
          (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
          (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
        item.varientRev =
          (item?.left_REV ? parseInt(item?.left_REV) : 0) -
          (item?.right_REV ? parseInt(item?.right_REV) : 0);

        if (item?.left_RMS) {
          total.leftRoom = total.leftRoom + parseInt(item?.left_RMS);
        }
        if (item?.left_ADR) {
          total.leftAdr = total.leftAdr + parseInt(item?.left_ADR);
        }
        if (item?.left_REV) {
          total.leftRev = total.leftRev + parseInt(item?.left_REV);
        }

        if (item?.right_RMS) {
          total.rightRoom = total.rightRoom + parseInt(item?.right_RMS);
        }
        if (item?.right_ADR) {
          total.rightAdr = total.rightAdr + parseInt(item?.right_ADR);
        }
        if (item?.right_REV) {
          total.rightRev = total.rightRev + parseInt(item?.right_REV);
        }

        return item;
      });

      setSegmentDrilldownData(groupingData);
      totalRef.current = total;
      setDropDownLLoading(false);
    }

    if (!response?.isValidate) {
      setSegmentDrilldownData([]);
      setDropDownLLoading(false);
    }
  };

  const getRateCodeData = async () => {
    // setDropDownLLoading(true);
    setMsLoading(true);
    let rateCodeResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_RATECODE,
        body: {
          leftAsOfDate: para?.asOfDate,
          leftpropertyCode: para?.propertyCode,
          leftstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(startDate)
          ),
          leftendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(endDate)
          ),
          rightAsOfDate: para?.asOfDate,
          rightpropertyCode: para?.propertyCode,
          rightstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightStartDate)
          ),
          rightendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightEndDate)
          ),
          selectedMarketSegment: selectedMarketSegmentRef.current,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, rateCodeResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.MarketSegment
        );

        if (findMarketsegment >= 0) {
          item.name = item?.RateCode;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items = [
            ...groupingJson?.[findMarketsegment]?.items,
            item,
          ];
        }
      });
      // rateCodeDataRef.current = true;

      calledMarketSegmentRef.current = [
        ...calledMarketSegmentRef.current,
        selectedMarketSegmentRef.current,
      ];
      setSegmentDrilldownData(groupingJson);
      // setDropDownLLoading(false);
      setMsLoading(false);
    }

    if (!response?.isValidate) {
      setMsLoading(false);
    }
  };

  const getCompanyData = async () => {
    // setDropDownLLoading(true);
    setRateCodeLoading(true);
    let companyDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_COMPANY,
        body: {
          leftAsOfDate: para?.asOfDate,
          leftpropertyCode: para?.propertyCode,
          leftstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(startDate)
          ),
          leftendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(endDate)
          ),
          rightAsOfDate: para?.asOfDate,
          rightpropertyCode: para?.propertyCode,
          rightstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightStartDate)
          ),
          rightendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightEndDate)
          ),
          selectedMarketSegment: selectedMarketSegmentRef.current,
          selectedRateCode: selectedRateCodeRef.current,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, companyDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.MarketSegment
        );
        let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
          (x) => x?.name === item?.RateCode
        );

        if (findRatecode >= 0) {
          item.name = item?.Company;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items[findRatecode].items = [
            ...groupingJson[findMarketsegment].items[findRatecode].items,
            item,
          ];
        }
      });
      // companyDataRef.current = true;
      calledRateCodeRef.current = [
        ...calledRateCodeRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      // setDropDownLLoading(false);
      setRateCodeLoading(false);
    }

    if (!response?.isValidate) {
      setRateCodeLoading(false);
    }
  };

  const getGuestData = async () => {
    // setDropDownLLoading(true);
    setCompanyLoading(true);
    let guestDataResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_GUEST,
        body: {
          leftAsOfDate: para?.asOfDate,
          leftpropertyCode: para?.propertyCode,
          leftstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(startDate)
          ),
          leftendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(endDate)
          ),
          rightAsOfDate: para?.asOfDate,
          rightpropertyCode: para?.propertyCode,
          rightstartDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightStartDate)
          ),
          rightendDate: commonService.getDateForNode(
            commonService.convertDateInTimezone(rightEndDate)
          ),
          selectedMarketSegment: selectedMarketSegmentRef.current,
          selectedRateCode: selectedRateCodeRef.current,
          selectedCompany: selectedCompanyRef.current,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, guestDataResponse);

    if (response?.isValidate) {
      let dataFromApi = response?.data?.data;

      let groupingJson = [...segmentDrilldownData];

      dataFromApi?.forEach((item) => {
        let findMarketsegment = groupingJson?.findIndex(
          (x) => x?.name === item?.MarketSegment
        );
        let findRatecode = groupingJson[findMarketsegment].items?.findIndex(
          (x) => x?.name === item?.RateCode
        );

        let findCompany = groupingJson[findMarketsegment]?.items[
          findRatecode
        ]?.items?.findIndex((x) => x?.name === item?.Company);

        if (findCompany >= 0) {
          item.name = item?.GuestName;
          item.items = [];
          item.varientRms =
            (item?.left_RMS ? parseInt(item?.left_RMS) : 0) -
            (item?.right_RMS ? parseInt(item?.right_RMS) : 0);
          item.varientAdr =
            (item?.left_ADR ? parseInt(item?.left_ADR) : 0) -
            (item?.right_ADR ? parseInt(item?.right_ADR) : 0);
          item.varientRev =
            (item?.left_REV ? parseInt(item?.left_REV) : 0) -
            (item?.right_REV ? parseInt(item?.right_REV) : 0);
          item.guid = commonService.GenerateGUID();
          groupingJson[findMarketsegment].items[findRatecode].items[
            findCompany
          ].items = [
            ...groupingJson[findMarketsegment].items[findRatecode].items[
              findCompany
            ].items,
            item,
          ];
        }
      });
      // guestDataRef.current = true;
      calledCompanyRef.current = [
        ...calledCompanyRef.current,
        {
          ms: selectedMarketSegmentRef.current,
          rc: selectedRateCodeRef.current,
          company: selectedCompanyRef.current,
        },
      ];
      setSegmentDrilldownData(groupingJson);
      // setDropDownLLoading(false);
      setCompanyLoading(false);
    }

    if (!response?.isValidate) {
      setCompanyLoading(false);
    }
  };
  const getNinentyDayTotal = async () => {
    setTotalLoading(true);
    let totalResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_OUTLOOK.GET_OUTLOOK_TOTAL,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          propertyid: propertyDetailFromRTK?.propertyid,
        },
      },
      false
    );

    let response = apiResponse(false, totalResponse);

    if (response?.isValidate) {
      setTotalData(response?.data?.data);
      setTotalLoading(false);
    }

    if (!response?.isValidate) {
      setTotalData(null);
      setTotalLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        getMarketSegmentData();
      }
    }
  }, [para?.asOfDate, callApi.current]);

  useLayoutEffect(() => {
    if (clickedTrRef.current) {
      showOnApiCall(clickedTrRef.current);
    }
  }, [
    rateCodeDataRef.current,
    companyDataRef.current,
    guestDataRef.current,
    segmentDrilldownData,
  ]);

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        let datesArr = commonService.getDateBetweenTwoDates(
          commonService.convertDateInTimezone(startDate),
          commonService.convertDateInTimezone(endDate)
        );
        startDateArrayRef.current = datesArr;
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getORGDetail();
        getNoteByDataList();
        getPickupFromData();
        getSevenPickupFromData();
        getForecastDetail();
        rateShopListAsOfDate();
        getLyDetail();
        getNetStlyDetail();
        getNinentyDayTotal();
      }
    }
  }, [para?.asOfDate, selectPickupDate, selectSevenPickupDate]);

  const [tblids, settblids] = useState({
    ninentyDaysTable: commonService.GenerateGUID(),
    ninentydayDropdown: commonService.GenerateGUID(),
  });

  commonService.setBackColorPerByTDValue(
    "#638ec6",
    "Red",
    `#${tblids.ninentyDaysTable} .90Outlook-otb`
  );
  commonService.setBackColorPerByTDValue(
    "#638ec6",
    "Red",
    `#${tblids.ninentyDaysTable} .90OutlookLyRms`
  );
  commonService.setBackColorPerByTDValue(
    "#638ec6",
    "Red",
    `#${tblids.ninentyDaysTable} .90OutlookStlyRms`
  );
  commonService.setBackColorPerByTDValue(
    "#63c384",
    "Red",
    `#${tblids.ninentyDaysTable} .90OutlookLyAdr`
  );
  commonService.setBackColorPerByTDValue(
    "#63c384",
    "Red",
    `#${tblids.ninentyDaysTable} .90OutlookStlyAdr`
  );

  const handleExport = async (tableClassName, widgetName, type) => {
    let tableId = document.querySelector(
      `.widget-container .${tableClassName}`
    );

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    let groupingJson = {
      data: [],
      filename: `${para?.propertyCode}-DayOutlook-${moment(new Date()).format(
        "MMDDYYYY"
      )}`,
    };

    let groupingTable = [
      {
        widgetname: widgetName,
        rowdata: [],
      },
    ];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [],
      },
    ];
    groupingTable[0].rowdata.push({
      data: [para?.rowTitle ? para?.rowTitle : ""],
    });
    groupingTable[0].rowdata.push({
      data: [],
    });
    const getTable = document?.querySelector(
      `.widget-container .${tableClassName} .ViewPropertySetupTable table`
    );

    const findTblHeader = getTable?.querySelectorAll("thead tr");
    const findTblTbody = getTable?.querySelectorAll("tbody tr");

    findTblHeader.forEach((tblHeaderItem, tblHeaderIndex) => {
      let allTh = tblHeaderItem.querySelectorAll("th");
      let allThValue = [];
      allTh.forEach((thItem) => {
        let thSpan = thItem?.querySelector("div>span");
        let thText = thItem?.innerText;
        allThValue.push(thSpan ? thSpan.innerText : thText);
      });

      groupingTable[0].rowdata.push({ data: allThValue });
      if (
        tableClassName === "thirtyOutlook-widget-container" &&
        tblHeaderIndex === 0
      ) {
        groupingTable[0].rowdata.push({ data: [] });
      }

      if (
        tableClassName === "sixtyOutlook-widget-container" &&
        tblHeaderIndex === 0
      ) {
        groupingTable[0].rowdata.push({ data: [] });
      }

      if (
        tableClassName === "ninentyOutlook-widget-container" &&
        tblHeaderIndex === 0
      ) {
        groupingTable[0].rowdata.push({ data: [] });
      }
    });

    findTblTbody.forEach((tblBodyItem) => {
      let allTd = tblBodyItem.querySelectorAll("td");
      let allTdValue = [],
        totalTdValue = [];

      allTd.forEach((tdItem, tdIndex) => {
        let tdText = tdItem?.innerText;
        if (
          allTd[0].innerText === "WeekDay" ||
          allTd[0].innerText === "WeekEnd" ||
          allTd[0].innerText === "Sun" ||
          allTd[0].innerText === "Mon" ||
          allTd[0].innerText === "Tue" ||
          allTd[0].innerText === "Wed" ||
          allTd[0].innerText === "Thu" ||
          allTd[0].innerText === "Fri" ||
          allTd[0].innerText === "Sat" ||
          allTd[0].innerText === "Total"
        ) {
          totalTdValue.push(tdText ? tdText : "");
        } else {
          if (tdIndex === 21 || tdIndex === 24) {
            tdText = tdText?.replace("$", "");
          }
          allTdValue.push(tdText ? tdText : "");
        }
      });
      if (allTdValue?.length > 0) {
        groupingTable[0].rowdata.push({ data: allTdValue });
      }
      if (totalTdValue?.length > 0) {
        totalJson[0].rowdata.push({ data: totalTdValue });
      }
    });
    if (groupingTable[0].rowdata?.length > 0) {
      groupingJson.data = [...groupingJson.data, ...groupingTable];
    }
    if (totalJson[0].rowdata?.length > 0) {
      groupingJson.data = [...groupingJson.data, ...totalJson];
    }
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  FixColumn(tblids.ninentyDaysTable, [1]);

  return (
    <div>
      <p>90 Days</p>
      <div className="ninentyOutlook-widget-container">
        {api !== 0 && (
          <div className="gap-1 ninentyOutlook-widget-container-menu">
            {/* <Button className="heading-menu-btns" type="button">
              <img
                src={NoteIcon}
                alt="menuIcon"
                onClick={() => {
                  clickedDateForNote.current = "";
                  // setShowNoteSidebar(true);
                  setAddNoteModal(true);
                }}
              />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  if (
                    window.location.href.includes("RevPAK%20Summary") ||
                    window.location.href.includes("90%20Day%20Outlook")
                  ) {
                    handleExport(
                      "ninentyOutlook-widget-container",
                      "NinentyDayOutlook",
                      "EMAIL"
                    );
                  } else {
                    commonService.fnSendWidgetMail(
                      tblids.ninentyDaysTable,
                      "90 Days Outlook",
                      snapshotId,
                      defaultAsOfDateFromRTK,
                      "",
                      para?.widgetId
                    );
                  }
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.ninetyDayOutlook,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.ninentyDaysTable}
                onClick={() => {
                  if (
                    window.location.href.includes("RevPAK%20Summary") ||
                    window.location.href.includes("90%20Day%20Outlook")
                  ) {
                    handleExport(
                      "ninentyOutlook-widget-container",
                      "NinentyDayOutlook",
                      "DOWNLOAD"
                    );
                  } else {
                    commonService.fnExportInCSV(
                      tblids.ninentyDaysTable,
                      // "90 Days Outlook"
                      `${para?.propertyCode}-90 Days Outlook-${moment(
                        new Date()
                      ).format("MMDDYYYY")}`
                    );
                  }
                }}
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable">
          <div className="stripped__table">
            <div className="table-responsive tblDynamicPickupSticky">
              <Table
                id={tblids.ninentyDaysTable}
                className="table-with-spacing positionStaticTable"
              >
                <thead className="tbl-header-sticky">
                  <tr className="table-main-header-tr">
                    <th className="border_right"></th>
                    <th className="border_right"></th>
                    <th colSpan={5} className="text-center border_right">
                      On The Books
                    </th>
                    <th colSpan={3} className="border_right">
                      <div className="">
                        <span>1 Day Pickup</span>
                        <DatePicker
                          className=""
                          id="pickupDate"
                          maxDate={new Date(maxDateRef.current)}
                          selected={
                            selectPickupDate
                              ? commonService.convertDateInTimezone(
                                  selectPickupDate
                                )
                              : commonService.convertDateInTimezone(
                                  maxDateRef.current
                                )
                          }
                          onChange={(date) => {
                            let year = date.getFullYear();
                            let month = date.getMonth();
                            let dateformat = date.getDate();
                            // let month =
                            //   date.getMonth() + 1 > 9
                            //     ? date.getMonth() + 1
                            //     : `0${date.getMonth() + 1}`;
                            // let dateFormat =
                            //   date?.getDate() > 9
                            //     ? date.getDate()
                            //     : `0${date.getDate()}`;
                            // let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                            let selectedDate = new Date(
                              year,
                              month,
                              dateformat
                            );
                            singleDatePickupDateRef.current = selectedDate;
                            setSelectPickupDate(selectedDate);
                          }}
                        />
                        <span style={{ display: "none" }}>
                          {selectPickupDate
                            ? commonService.getDateInFormat(
                                new Date(selectPickupDate)
                              )
                            : commonService.getDateInFormat(maxDateRef.current)}
                        </span>
                      </div>
                    </th>
                    <th colSpan={3} className="border_right">
                      <div className="">
                        <span>7 Day Pickup</span>
                        <DatePicker
                          className=""
                          id="pickupDate"
                          maxDate={new Date(maxDateRef.current)}
                          selected={
                            selectSevenPickupDate
                              ? commonService.convertDateInTimezone(
                                  selectSevenPickupDate
                                )
                              : commonService.convertDateInTimezone(
                                  sevenMaxDateRef.current
                                )
                            // : new Date(maxDateRef.current).setDate(
                            //     new Date(maxDateRef.current).getDate() - 7
                            //   )
                          }
                          onChange={(date) => {
                            let year = date.getFullYear();
                            let month = date.getMonth();
                            let dateformat = date.getDate();
                            let selectedDate = new Date(
                              year,
                              month,
                              dateformat
                            );
                            // let month =
                            //   date.getMonth() + 1 > 9
                            //     ? date.getMonth() + 1
                            //     : `0${date.getMonth() + 1}`;
                            // let dateFormat =
                            //   date?.getDate() > 9
                            //     ? date.getDate()
                            //     : `0${date.getDate()}`;
                            // let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                            singleDateSevenPickupDateRef.current = selectedDate;
                            setSelectSevenPickupDate(selectedDate);
                          }}
                        />
                        <span style={{ display: "none" }}>
                          {selectSevenPickupDate
                            ? commonService.getDateInFormat(
                                new Date(selectSevenPickupDate)
                              )
                            : commonService.getDateInFormat(
                                // new Date(maxDateRef.current).setDate(
                                //   new Date(maxDateRef.current).getDate() - 7
                                // )
                                sevenMaxDateRef.current
                              )}
                        </span>
                      </div>
                    </th>
                    <th className="border_right text-center">Forecast</th>

                    <th colSpan={3} className="text-center border_right">
                      LY
                    </th>
                    <th colSpan={3} className="text-center border_right">
                      Net Stly
                    </th>
                    <th
                      colSpan={
                        rateShopListData
                          ? rateShopListData?.filter(
                              (x) =>
                                x.CheckInDate ===
                                moment(
                                  rateShopListData?.[0]?.CheckInDate
                                ).format("YYYY-MM-DD")
                            )?.length + 1
                          : 6
                      }
                      className="text-center"
                    >
                      RateShop
                    </th>
                  </tr>
                  <tr className="table-main-header-tr">
                    <th
                      className="width175 border_right"
                      onClick={() => {
                        setOpenDatePicker(true);
                      }}
                    >
                      Date
                      <DatePicker
                        onChange={(event) => handleDateChange(event)}
                        startDate={startRangeDate}
                        endDate={endRangeDate}
                        selectsRange
                        monthsShown={2}
                        open={openDatePicker}
                        shouldCloseOnSelect={false}
                      >
                        <div
                          className="w-100"
                          style={{ display: "inline-block" }}
                        >
                          <div className="content-inside-calendar">
                            <p>
                              {startRangeDate
                                ? commonService.getDateInFormat(startRangeDate)
                                : ""}
                              {startRangeDate && endRangeDate ? " - " : ""}
                              {endRangeDate
                                ? commonService.getDateInFormat(endRangeDate)
                                : ""}
                            </p>
                            <div className="d-flex align-items-center gap-2">
                              <button
                                className="secondary-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setStartRangeDate("");
                                  setEndRangeDate("");
                                  setOpenDatePicker(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={
                                  startRangeDate && endRangeDate ? false : true
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (
                                    startRangeDateRef.current &&
                                    endRangeDateRef.current
                                  ) {
                                    let datesArr =
                                      commonService.getDateBetweenTwoDates(
                                        commonService.convertDateInTimezone(
                                          startRangeDateRef.current
                                        ),
                                        commonService.convertDateInTimezone(
                                          endRangeDateRef.current
                                        )
                                      );
                                    startDateArrayRef.current = datesArr;
                                    //call api when date changes
                                    getORGDetail();
                                    getNoteByDataList();
                                    getPickupFromData();
                                    getSevenPickupFromData();
                                    getForecastDetail();
                                    rateShopListAsOfDate();
                                    getLyDetail();
                                    getNetStlyDetail();
                                    getNinentyDayTotal();
                                  }

                                  setOpenDatePicker(false);
                                }}
                                className="primary-btn"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </DatePicker>
                      {startRangeDate && endRangeDate ? (
                        <span style={{ display: "none" }}>
                          {`${commonService.getDateInFormat(
                            startRangeDate
                          )} - ${commonService.getDateInFormat(endRangeDate)}`}
                        </span>
                      ) : (
                        ""
                      )}
                    </th>
                    <th className="border_right">Notes</th>
                    <th className="width75 text-center">Left To Sell</th>
                    <th className="width75 text-center">On The Books</th>
                    <th className="width75 text-center">
                      Total Occ Percentage
                    </th>
                    <th className="width75 text-end">ADR</th>
                    <th className="width75 text-end border_right">Revenue</th>
                    <th className="text-center">RMS</th>
                    <th className="text-end">ADR</th>
                    <th className="border_right text-end">Rev</th>
                    <th className="text-center">RMS</th>
                    <th className="text-end">ADR</th>
                    <th className="border_right text-end">Rev</th>
                    <th className="border_right text-center">Rooms</th>
                    <th className="text-center border-right">RMS</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end border_right">REV</th>
                    <th className="text-center">RMS</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end border_right">REV</th>
                    {rateShopListData &&
                      rateShopListData?.map((x) => {
                        if (
                          x.CheckInDate ===
                            moment(rateShopListData?.[0]?.CheckInDate).format(
                              "YYYY-MM-DD"
                            ) &&
                          x.IsSelf === true
                        ) {
                          return (
                            <th
                              className="text-end"
                              title={x?.competiterpropertyname}
                            >
                              {x?.competiterpropertyname?.length > 10
                                ? `${x?.competiterpropertyname?.slice(
                                    0,
                                    10
                                  )}...`
                                : x?.competiterpropertyname}
                            </th>
                          );
                        }
                      })}
                    {rateShopListData && (
                      <th className="text-end">Avg Competitor</th>
                    )}
                    {rateShopListData &&
                      rateShopListData?.map((x, indexpfRateshop) => {
                        if (
                          x.CheckInDate ===
                            moment(rateShopListData?.[0]?.CheckInDate).format(
                              "YYYY-MM-DD"
                            ) &&
                          x.IsSelf === false
                        ) {
                          return (
                            <th
                              className={`text-end`}
                              title={x?.competiterpropertyname}
                            >
                              {x?.competiterpropertyname?.length > 10
                                ? `${x?.competiterpropertyname?.slice(
                                    0,
                                    10
                                  )}...`
                                : x?.competiterpropertyname}
                            </th>
                          );
                        }
                      })}
                    {!rateShopListData && <th colSpan={6}></th>}
                  </tr>
                </thead>
                <tbody>
                  {loading === true ||
                  pickupLoading === true ||
                  sevenPickupLoading === true ||
                  forecastLoading === true ||
                  rateShopLoading === true ||
                  lyLoading === true ||
                  netstlyLoading === true ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    // orgData &&
                    // orgData?.map((item, index) => {
                    startDateArrayRef.current?.map((item, index) => {
                      dayName = false;
                      if (
                        moment(
                          // new Date(startDateArrayRef.current[index]),
                          new Date(item),
                          "l"
                        ).format("ddd") === "Fri" ||
                        moment(
                          // new Date(startDateArrayRef.current[index]),
                          new Date(item),
                          "l"
                        ).format("ddd") === "Sat"
                      ) {
                        dayName = true;
                      }

                      let checkNoteExists = noteDetail?.notedata?.filter(
                        (x) =>
                          moment(x?.datadate).format("YYYY-MM-DD") ===
                          moment(item).format("YYYY-MM-DD")
                      );

                      let singleOTBData = orgData?.filter(
                        (x) =>
                          moment(x.Date).format("YYYY-MM-DD") ===
                          moment(item).format("YYYY-MM-DD")
                        // moment(startDateArrayRef.current[index]).format(
                        //   "YYYY-MM-DD"
                        // )
                      );

                      let onePickupData = pickupFromData?.filter(
                        (x) =>
                          moment(x.Dates).format("YYYY-MM-DD") ===
                          moment(item).format("YYYY-MM-DD")
                        // moment(startDateArrayRef.current[index]).format(
                        //   "YYYY-MM-DD"
                        // )
                      );

                      let sevenPickupData = sevenPickupFromData?.filter(
                        (x) =>
                          moment(x.Dates).format("YYYY-MM-DD") ===
                          moment(item).format("YYYY-MM-DD")
                        // moment(startDateArrayRef.current[index]).format(
                        //   "YYYY-MM-DD"
                        // )
                      );

                      let singleForecastData = forecastData?.filter(
                        (x) =>
                          moment(x.Dates).format("YYYY-MM-DD") ===
                          moment(item).format("YYYY-MM-DD")
                        // moment(startDateArrayRef.current[index]).format(
                        //   "YYYY-MM-DD"
                        // )
                      );

                      let singleLyData = lyData?.filter(
                        (x) =>
                          moment(x.Date).format("YYYY-MM-DD") ===
                          moment(
                            // new Date(startDateArrayRef.current[index]).setFullYear(
                            //   new Date(
                            //     startDateArrayRef.current[index]
                            //   ).getFullYear() - 1
                            // )
                            new Date(item).setFullYear(
                              new Date(item).getFullYear() - 1
                            )
                          ).format("YYYY-MM-DD")
                      );

                      let singleNetStlyData = netStlyData?.filter(
                        (x) =>
                          moment(x.Date).format("YYYY-MM-DD") ===
                          moment(
                            // new Date(startDateArrayRef.current[index]).setFullYear(
                            //   new Date(
                            //     startDateArrayRef.current[index]
                            //   ).getFullYear() - 1
                            // )
                            new Date(item).setFullYear(
                              new Date(item).getFullYear() - 1
                            )
                          ).format("YYYY-MM-DD")
                      );

                      if (singleOTBData?.[0]?.LeftToSell) {
                        leftToSell =
                          leftToSell + parseInt(singleOTBData?.[0]?.LeftToSell);
                      }
                      if (singleOTBData?.[0]?.OnTheBook) {
                        otb = otb + parseInt(singleOTBData?.[0]?.OnTheBook);
                      }
                      if (singleOTBData?.[0]?.TotalOCCPercentage) {
                        occPercentage =
                          occPercentage +
                          parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                      }
                      if (singleOTBData?.[0]?.ADR) {
                        adr = adr + parseInt(singleOTBData?.[0]?.ADR);
                      }
                      if (singleOTBData?.[0]?.REV) {
                        rev = rev + parseInt(singleOTBData?.[0]?.REV);
                      }
                      if (onePickupData?.[0]?.RMS) {
                        oneRms = oneRms + parseInt(onePickupData?.[0]?.RMS);
                      }
                      if (onePickupData?.[0]?.ADR) {
                        oneAdr = oneAdr + parseInt(onePickupData?.[0]?.ADR);
                      }
                      if (onePickupData?.[0]?.REV) {
                        oneRev = oneRev + parseInt(onePickupData?.[0]?.REV);
                      }
                      if (sevenPickupData?.[0]?.RMS) {
                        sevenRms =
                          sevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                      }
                      if (sevenPickupData?.[0]?.ADR) {
                        sevenAdr =
                          sevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                      }
                      if (sevenPickupData?.[0]?.REV) {
                        sevenRev =
                          sevenRev + parseInt(sevenPickupData?.[0]?.REV);
                      }

                      if (singleLyData?.[0]?.RMS) {
                        lytotalRms =
                          lytotalRms + parseInt(singleLyData?.[0]?.RMS);
                      }
                      if (singleLyData?.[0]?.REV) {
                        lytotallyRev =
                          lytotallyRev + parseInt(singleLyData?.[0]?.REV);
                      }
                      if (singleLyData?.[0]?.ADR) {
                        lytotallyAdr =
                          lytotallyAdr + parseInt(singleLyData?.[0]?.ADR);
                      }

                      if (singleNetStlyData?.[0]?.RMS) {
                        netStlyTotalRms =
                          netStlyTotalRms +
                          parseInt(singleNetStlyData?.[0]?.RMS);
                      }
                      if (singleNetStlyData?.[0]?.REV) {
                        netStlyTotalRev =
                          netStlyTotalRev +
                          parseInt(singleNetStlyData?.[0]?.REV);
                      }
                      if (singleNetStlyData?.[0]?.ADR) {
                        netStlyTotalAdr =
                          netStlyTotalAdr +
                          parseInt(singleNetStlyData?.[0]?.ADR);
                      }
                      if (onePickupData?.[0]?.ADR) {
                        onedayAdrCount = onedayAdrCount + 1;
                      }
                      if (sevenPickupData?.[0]?.ADR) {
                        sevendayAdrCount = sevendayAdrCount + 1;
                      }

                      if (
                        moment(
                          // new Date(startDateArrayRef.current[index]),
                          new Date(item),
                          "l"
                        ).format("ddd") === "Sun" ||
                        moment(
                          // new Date(startDateArrayRef.current[index]),
                          new Date(item),
                          "l"
                        ).format("ddd") === "Sat"
                      ) {
                        totalWeekendDays = totalWeekendDays + 1;
                        if (onePickupData?.[0]?.ADR) {
                          weekendOneDayAdrCount = weekendOneDayAdrCount + 1;
                        }
                        if (sevenPickupData?.[0]?.ADR) {
                          weekendsevenDayAdrCount = weekendsevenDayAdrCount + 1;
                        }
                        if (
                          moment(
                            // new Date(startDateArrayRef.current[index]),
                            new Date(item),
                            "l"
                          ).format("ddd") === "Sat"
                        ) {
                          sattotalDay = sattotalDay + 1;
                          if (onePickupData?.[0]?.ADR) {
                            satoneDayAdrCount = satoneDayAdrCount + 1;
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            satsevenDayAdrCount = satsevenDayAdrCount + 1;
                          }
                          if (singleOTBData?.[0]?.LeftToSell) {
                            satLeftToSell =
                              satLeftToSell +
                              parseInt(singleOTBData?.[0]?.LeftToSell);
                          }
                          if (singleOTBData?.[0]?.OnTheBook) {
                            satOtb =
                              satOtb + parseInt(singleOTBData?.[0]?.OnTheBook);
                          }
                          if (singleOTBData?.[0]?.TotalOCCPercentage) {
                            satOccPercentage =
                              satOccPercentage +
                              parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                          }
                          if (singleOTBData?.[0]?.ADR) {
                            satAdr = satAdr + parseInt(singleOTBData?.[0]?.ADR);
                          }
                          if (singleOTBData?.[0]?.REV) {
                            satRev = satRev + parseInt(singleOTBData?.[0]?.REV);
                          }
                          if (onePickupData?.[0]?.RMS) {
                            satoneRms =
                              satoneRms + parseInt(onePickupData?.[0]?.RMS);
                          }
                          if (onePickupData?.[0]?.ADR) {
                            satoneAdr =
                              satoneAdr + parseInt(onePickupData?.[0]?.ADR);
                          }
                          if (onePickupData?.[0]?.REV) {
                            satoneRev =
                              satoneRev + parseInt(onePickupData?.[0]?.REV);
                          }
                          if (sevenPickupData?.[0]?.RMS) {
                            satsevenRms =
                              satsevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            satsevenAdr =
                              satsevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                          }
                          if (sevenPickupData?.[0]?.REV) {
                            satsevenRev =
                              satsevenRev + parseInt(sevenPickupData?.[0]?.REV);
                          }

                          if (singleLyData?.[0]?.RMS) {
                            satlyRms =
                              satlyRms + parseInt(singleLyData?.[0]?.RMS);
                          }
                          if (singleLyData?.[0]?.REV) {
                            satlyRev =
                              satlyRev + parseInt(singleLyData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.ADR) {
                            satlyAdr =
                              satlyAdr + parseInt(singleLyData?.[0]?.ADR);
                          }

                          if (singleNetStlyData?.[0]?.RMS) {
                            satNetstlyRms =
                              satNetstlyRms +
                              parseInt(singleNetStlyData?.[0]?.RMS);
                          }
                          if (singleNetStlyData?.[0]?.REV) {
                            satNetstlyRev =
                              satNetstlyRev +
                              parseInt(singleNetStlyData?.[0]?.REV);
                          }
                          if (singleNetStlyData?.[0]?.ADR) {
                            satNetstlyAdr =
                              satNetstlyAdr +
                              parseInt(singleNetStlyData?.[0]?.ADR);
                          }
                        }
                        if (
                          moment(
                            // new Date(startDateArrayRef.current[index]),
                            new Date(item),
                            "l"
                          ).format("ddd") === "Sun"
                        ) {
                          suntotalDay = suntotalDay + 1;
                          if (onePickupData?.[0]?.ADR) {
                            sunoneDayAdrCount = sunoneDayAdrCount + 1;
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            sunsevenDayAdrCount = sunsevenDayAdrCount + 1;
                          }
                          if (singleOTBData?.[0]?.LeftToSell) {
                            sunLeftToSell =
                              sunLeftToSell +
                              parseInt(singleOTBData?.[0]?.LeftToSell);
                          }
                          if (singleOTBData?.[0]?.OnTheBook) {
                            sunOtb =
                              sunOtb + parseInt(singleOTBData?.[0]?.OnTheBook);
                          }
                          if (singleOTBData?.[0]?.TotalOCCPercentage) {
                            sunOccPercentage =
                              sunOccPercentage +
                              parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                          }
                          if (singleOTBData?.[0]?.ADR) {
                            sunAdr = sunAdr + parseInt(singleOTBData?.[0]?.ADR);
                          }
                          if (singleOTBData?.[0]?.REV) {
                            sunRev = sunRev + parseInt(singleOTBData?.[0]?.REV);
                          }
                          if (onePickupData?.[0]?.RMS) {
                            sunoneRms =
                              sunoneRms + parseInt(onePickupData?.[0]?.RMS);
                          }
                          if (onePickupData?.[0]?.ADR) {
                            sunoneAdr =
                              sunoneAdr + parseInt(onePickupData?.[0]?.ADR);
                          }
                          if (onePickupData?.[0]?.REV) {
                            sunoneRev =
                              sunoneRev + parseInt(onePickupData?.[0]?.REV);
                          }
                          if (sevenPickupData?.[0]?.RMS) {
                            sunsevenRms =
                              sunsevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            sunsevenAdr =
                              sunsevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                          }
                          if (sevenPickupData?.[0]?.REV) {
                            sunsevenRev =
                              sunsevenRev + parseInt(sevenPickupData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.RMS) {
                            sunlyRms =
                              sunlyRms + parseInt(singleLyData?.[0]?.RMS);
                          }
                          if (singleLyData?.[0]?.REV) {
                            sunlyRev =
                              sunlyRev + parseInt(singleLyData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.ADR) {
                            sunlyAdr =
                              sunlyAdr + parseInt(singleLyData?.[0]?.ADR);
                          }

                          if (singleNetStlyData?.[0]?.RMS) {
                            sunNetstlyRms =
                              sunNetstlyRms +
                              parseInt(singleNetStlyData?.[0]?.RMS);
                          }
                          if (singleNetStlyData?.[0]?.REV) {
                            sunNetstlyRev =
                              sunNetstlyRev +
                              parseInt(singleNetStlyData?.[0]?.REV);
                          }
                          if (singleNetStlyData?.[0]?.ADR) {
                            sunNetstlyAdr =
                              sunNetstlyAdr +
                              parseInt(singleNetStlyData?.[0]?.ADR);
                          }
                        }

                        if (singleLyData?.[0]?.RMS) {
                          weekendlyRms =
                            weekendlyRms + parseInt(singleLyData?.[0]?.RMS);
                        }
                        if (singleLyData?.[0]?.REV) {
                          weekendlyRev =
                            weekendlyRev + parseInt(singleLyData?.[0]?.REV);
                        }
                        if (singleLyData?.[0]?.ADR) {
                          weekendlyAdr =
                            weekendlyAdr + parseInt(singleLyData?.[0]?.ADR);
                        }

                        if (singleNetStlyData?.[0]?.RMS) {
                          weekendNetstlyRms =
                            weekendNetstlyRms +
                            parseInt(singleNetStlyData?.[0]?.RMS);
                        }
                        if (singleNetStlyData?.[0]?.REV) {
                          weekendNetstlyRev =
                            weekendNetstlyRev +
                            parseInt(singleNetStlyData?.[0]?.REV);
                        }
                        if (singleNetStlyData?.[0]?.ADR) {
                          weekendNetstlyAdr =
                            weekendNetstlyAdr +
                            parseInt(singleNetStlyData?.[0]?.ADR);
                        }

                        if (singleOTBData?.[0]?.LeftToSell) {
                          weekendLeftToSell =
                            weekendLeftToSell +
                            parseInt(singleOTBData?.[0]?.LeftToSell);
                        }
                        if (singleOTBData?.[0]?.OnTheBook) {
                          weekendOtb =
                            weekendOtb +
                            parseInt(singleOTBData?.[0]?.OnTheBook);
                        }
                        if (singleOTBData?.[0]?.TotalOCCPercentage) {
                          weekendOccPercentage =
                            weekendOccPercentage +
                            parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                        }
                        if (singleOTBData?.[0]?.ADR) {
                          weekendAdr =
                            weekendAdr + parseInt(singleOTBData?.[0]?.ADR);
                        }
                        if (singleOTBData?.[0]?.REV) {
                          weekendRev =
                            weekendRev + parseInt(singleOTBData?.[0]?.REV);
                        }
                        if (onePickupData?.[0]?.RMS) {
                          weekendoneRms =
                            weekendoneRms + parseInt(onePickupData?.[0]?.RMS);
                        }
                        if (onePickupData?.[0]?.ADR) {
                          weekendoneAdr =
                            weekendoneAdr + parseInt(onePickupData?.[0]?.ADR);
                        }
                        if (onePickupData?.[0]?.REV) {
                          weekendoneRev =
                            weekendoneRev + parseInt(onePickupData?.[0]?.REV);
                        }
                        if (sevenPickupData?.[0]?.RMS) {
                          weekendsevenRms =
                            weekendsevenRms +
                            parseInt(sevenPickupData?.[0]?.RMS);
                        }
                        if (sevenPickupData?.[0]?.ADR) {
                          weekendsevenAdr =
                            weekendsevenAdr +
                            parseInt(sevenPickupData?.[0]?.ADR);
                        }
                        if (sevenPickupData?.[0]?.REV) {
                          weekendsevenRev =
                            weekendsevenRev +
                            parseInt(sevenPickupData?.[0]?.REV);
                        }
                      } else {
                        totalWeekDays = totalWeekDays + 1;
                        if (onePickupData?.[0]?.ADR) {
                          weekdayOneDayAdrCount = weekdayOneDayAdrCount + 1;
                        }
                        if (sevenPickupData?.[0]?.ADR) {
                          weekdaysevenDayAdrCount = weekdaysevenDayAdrCount + 1;
                        }
                        if (singleOTBData?.[0]?.LeftToSell) {
                          weekdayLeftToSell =
                            weekdayLeftToSell +
                            parseInt(singleOTBData?.[0]?.LeftToSell);
                        }
                        if (singleOTBData?.[0]?.OnTheBook) {
                          weekdayOtb =
                            weekdayOtb +
                            parseInt(singleOTBData?.[0]?.OnTheBook);
                        }
                        if (singleOTBData?.[0]?.TotalOCCPercentage) {
                          weekdayOccPercentage =
                            weekdayOccPercentage +
                            parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                        }
                        if (singleOTBData?.[0]?.ADR) {
                          weekdayAdr =
                            weekdayAdr + parseInt(singleOTBData?.[0]?.ADR);
                        }
                        if (singleOTBData?.[0]?.REV) {
                          weekdayRev =
                            weekdayRev + parseInt(singleOTBData?.[0]?.REV);
                        }
                        if (onePickupData?.[0]?.RMS) {
                          weekdayoneRms =
                            weekdayoneRms + parseInt(onePickupData?.[0]?.RMS);
                        }
                        if (onePickupData?.[0]?.ADR) {
                          weekdayoneAdr =
                            weekdayoneAdr + parseInt(onePickupData?.[0]?.ADR);
                        }
                        if (onePickupData?.[0]?.REV) {
                          weekdayoneRev =
                            weekdayoneRev + parseInt(onePickupData?.[0]?.REV);
                        }
                        if (sevenPickupData?.[0]?.RMS) {
                          weekdaysevenRms =
                            weekdaysevenRms +
                            parseInt(sevenPickupData?.[0]?.RMS);
                        }
                        if (sevenPickupData?.[0]?.ADR) {
                          weekdaysevenAdr =
                            weekdaysevenAdr +
                            parseInt(sevenPickupData?.[0]?.ADR);
                        }
                        if (sevenPickupData?.[0]?.REV) {
                          weekdaysevenRev =
                            weekdaysevenRev +
                            parseInt(sevenPickupData?.[0]?.REV);
                        }

                        if (singleLyData?.[0]?.RMS) {
                          weekdaylyRms =
                            weekdaylyRms + parseInt(singleLyData?.[0]?.RMS);
                        }
                        if (singleLyData?.[0]?.REV) {
                          weekdaylyRev =
                            weekdaylyRev + parseInt(singleLyData?.[0]?.REV);
                        }
                        if (singleLyData?.[0]?.ADR) {
                          weekdaylyAdr =
                            weekdaylyAdr + parseInt(singleLyData?.[0]?.ADR);
                        }

                        if (singleNetStlyData?.[0]?.RMS) {
                          weekdayNetstlyRms =
                            weekdayNetstlyRms +
                            parseInt(singleNetStlyData?.[0]?.RMS);
                        }
                        if (singleNetStlyData?.[0]?.REV) {
                          weekdayNetstlyRev =
                            weekdayNetstlyRev +
                            parseInt(singleNetStlyData?.[0]?.REV);
                        }
                        if (singleNetStlyData?.[0]?.ADR) {
                          weekdayNetstlyAdr =
                            weekdayNetstlyAdr +
                            parseInt(singleNetStlyData?.[0]?.ADR);
                        }

                        if (
                          moment(
                            // new Date(startDateArrayRef.current[index]),
                            new Date(item),
                            "l"
                          ).format("ddd") === "Mon"
                        ) {
                          montotalDay = montotalDay + 1;
                          if (onePickupData?.[0]?.ADR) {
                            mononeDayAdrCount = mononeDayAdrCount + 1;
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            monsevenDayAdrCount = monsevenDayAdrCount + 1;
                          }

                          if (singleLyData?.[0]?.RMS) {
                            monlyRms =
                              monlyRms + parseInt(singleLyData?.[0]?.RMS);
                          }
                          if (singleLyData?.[0]?.REV) {
                            monlyRev =
                              monlyRev + parseInt(singleLyData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.ADR) {
                            monlyAdr =
                              monlyAdr + parseInt(singleLyData?.[0]?.ADR);
                          }

                          if (singleNetStlyData?.[0]?.RMS) {
                            monNetstlyRms =
                              monNetstlyRms +
                              parseInt(singleNetStlyData?.[0]?.RMS);
                          }
                          if (singleNetStlyData?.[0]?.REV) {
                            monNetstlyRev =
                              monNetstlyRev +
                              parseInt(singleNetStlyData?.[0]?.REV);
                          }
                          if (singleNetStlyData?.[0]?.ADR) {
                            monNetstlyAdr =
                              monNetstlyAdr +
                              parseInt(singleNetStlyData?.[0]?.ADR);
                          }

                          if (singleOTBData?.[0]?.LeftToSell) {
                            monLeftToSell =
                              monLeftToSell +
                              parseInt(singleOTBData?.[0]?.LeftToSell);
                          }
                          if (singleOTBData?.[0]?.OnTheBook) {
                            monOtb =
                              monOtb + parseInt(singleOTBData?.[0]?.OnTheBook);
                          }
                          if (singleOTBData?.[0]?.TotalOCCPercentage) {
                            monOccPercentage =
                              monOccPercentage +
                              parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                          }
                          if (singleOTBData?.[0]?.ADR) {
                            monAdr = monAdr + parseInt(singleOTBData?.[0]?.ADR);
                          }
                          if (singleOTBData?.[0]?.REV) {
                            monRev = monRev + parseInt(singleOTBData?.[0]?.REV);
                          }
                          if (onePickupData?.[0]?.RMS) {
                            mononeRms =
                              mononeRms + parseInt(onePickupData?.[0]?.RMS);
                          }
                          if (onePickupData?.[0]?.ADR) {
                            mononeAdr =
                              mononeAdr + parseInt(onePickupData?.[0]?.ADR);
                          }
                          if (onePickupData?.[0]?.REV) {
                            mononeRev =
                              mononeRev + parseInt(onePickupData?.[0]?.REV);
                          }
                          if (sevenPickupData?.[0]?.RMS) {
                            monsevenRms =
                              monsevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            monsevenAdr =
                              monsevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                          }
                          if (sevenPickupData?.[0]?.REV) {
                            monsevenRev =
                              monsevenRev + parseInt(sevenPickupData?.[0]?.REV);
                          }
                        }
                        if (
                          moment(
                            // new Date(startDateArrayRef.current[index]),
                            new Date(item),
                            "l"
                          ).format("ddd") === "Tue"
                        ) {
                          tuetotalDay = tuetotalDay + 1;
                          if (onePickupData?.[0]?.ADR) {
                            tueoneDayAdrCount = tueoneDayAdrCount + 1;
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            tuesevenDayAdrCount = tuesevenDayAdrCount + 1;
                          }

                          if (singleLyData?.[0]?.RMS) {
                            tuelyRms =
                              tuelyRms + parseInt(singleLyData?.[0]?.RMS);
                          }
                          if (singleLyData?.[0]?.REV) {
                            tuelyRev =
                              tuelyRev + parseInt(singleLyData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.ADR) {
                            tuelyAdr =
                              tuelyAdr + parseInt(singleLyData?.[0]?.ADR);
                          }

                          if (singleNetStlyData?.[0]?.RMS) {
                            tueNetstlyRms =
                              tueNetstlyRms +
                              parseInt(singleNetStlyData?.[0]?.RMS);
                          }
                          if (singleNetStlyData?.[0]?.REV) {
                            tueNetstlyRev =
                              tueNetstlyRev +
                              parseInt(singleNetStlyData?.[0]?.REV);
                          }
                          if (singleNetStlyData?.[0]?.ADR) {
                            tueNetstlyAdr =
                              tueNetstlyAdr +
                              parseInt(singleNetStlyData?.[0]?.ADR);
                          }

                          if (singleOTBData?.[0]?.LeftToSell) {
                            tueLeftToSell =
                              tueLeftToSell +
                              parseInt(singleOTBData?.[0]?.LeftToSell);
                          }
                          if (singleOTBData?.[0]?.OnTheBook) {
                            tueOtb =
                              tueOtb + parseInt(singleOTBData?.[0]?.OnTheBook);
                          }
                          if (singleOTBData?.[0]?.TotalOCCPercentage) {
                            tueOccPercentage =
                              tueOccPercentage +
                              parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                          }
                          if (singleOTBData?.[0]?.ADR) {
                            tueAdr = tueAdr + parseInt(singleOTBData?.[0]?.ADR);
                          }
                          if (singleOTBData?.[0]?.REV) {
                            tueRev = tueRev + parseInt(singleOTBData?.[0]?.REV);
                          }
                          if (onePickupData?.[0]?.RMS) {
                            tueoneRms =
                              tueoneRms + parseInt(onePickupData?.[0]?.RMS);
                          }
                          if (onePickupData?.[0]?.ADR) {
                            tueoneAdr =
                              tueoneAdr + parseInt(onePickupData?.[0]?.ADR);
                          }
                          if (onePickupData?.[0]?.REV) {
                            tueoneRev =
                              tueoneRev + parseInt(onePickupData?.[0]?.REV);
                          }
                          if (sevenPickupData?.[0]?.RMS) {
                            tuesevenRms =
                              tuesevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            tuesevenAdr =
                              tuesevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                          }
                          if (sevenPickupData?.[0]?.REV) {
                            tuesevenRev =
                              tuesevenRev + parseInt(sevenPickupData?.[0]?.REV);
                          }
                        }
                        if (
                          moment(
                            // new Date(startDateArrayRef.current[index]),
                            new Date(item),
                            "l"
                          ).format("ddd") === "Wed"
                        ) {
                          wedtotalDay = wedtotalDay + 1;
                          if (onePickupData?.[0]?.ADR) {
                            wedoneDayAdrCount = wedoneDayAdrCount + 1;
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            wedsevenDayAdrCount = wedsevenDayAdrCount + 1;
                          }

                          if (singleLyData?.[0]?.RMS) {
                            wedlyRms =
                              wedlyRms + parseInt(singleLyData?.[0]?.RMS);
                          }
                          if (singleLyData?.[0]?.REV) {
                            wedlyRev =
                              wedlyRev + parseInt(singleLyData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.ADR) {
                            wedlyAdr =
                              wedlyAdr + parseInt(singleLyData?.[0]?.ADR);
                          }

                          if (singleNetStlyData?.[0]?.RMS) {
                            wedNetstlyRms =
                              wedNetstlyRms +
                              parseInt(singleNetStlyData?.[0]?.RMS);
                          }
                          if (singleNetStlyData?.[0]?.REV) {
                            wedNetstlyRev =
                              wedNetstlyRev +
                              parseInt(singleNetStlyData?.[0]?.REV);
                          }
                          if (singleNetStlyData?.[0]?.ADR) {
                            wedNetstlyAdr =
                              wedNetstlyAdr +
                              parseInt(singleNetStlyData?.[0]?.ADR);
                          }

                          if (singleOTBData?.[0]?.LeftToSell) {
                            wedLeftToSell =
                              wedLeftToSell +
                              parseInt(singleOTBData?.[0]?.LeftToSell);
                          }
                          if (singleOTBData?.[0]?.OnTheBook) {
                            wedOtb =
                              wedOtb + parseInt(singleOTBData?.[0]?.OnTheBook);
                          }
                          if (singleOTBData?.[0]?.TotalOCCPercentage) {
                            wedOccPercentage =
                              wedOccPercentage +
                              parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                          }
                          if (singleOTBData?.[0]?.ADR) {
                            wedAdr = wedAdr + parseInt(singleOTBData?.[0]?.ADR);
                          }
                          if (singleOTBData?.[0]?.REV) {
                            wedRev = wedRev + parseInt(singleOTBData?.[0]?.REV);
                          }
                          if (onePickupData?.[0]?.RMS) {
                            wedoneRms =
                              wedoneRms + parseInt(onePickupData?.[0]?.RMS);
                          }
                          if (onePickupData?.[0]?.ADR) {
                            wedoneAdr =
                              wedoneAdr + parseInt(onePickupData?.[0]?.ADR);
                          }
                          if (onePickupData?.[0]?.REV) {
                            wedoneRev =
                              wedoneRev + parseInt(onePickupData?.[0]?.REV);
                          }
                          if (sevenPickupData?.[0]?.RMS) {
                            wedsevenRms =
                              wedsevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            wedsevenAdr =
                              wedsevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                          }
                          if (sevenPickupData?.[0]?.REV) {
                            wedsevenRev =
                              wedsevenRev + parseInt(sevenPickupData?.[0]?.REV);
                          }
                        }
                        if (
                          moment(
                            // new Date(startDateArrayRef.current[index]),
                            new Date(item),
                            "l"
                          ).format("ddd") === "Thu"
                        ) {
                          thutotalDay = thutotalDay + 1;
                          if (onePickupData?.[0]?.ADR) {
                            thuoneDayAdrCount = thuoneDayAdrCount + 1;
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            thusevenDayAdrCount = thusevenDayAdrCount + 1;
                          }

                          if (singleLyData?.[0]?.RMS) {
                            thulyRms =
                              thulyRms + parseInt(singleLyData?.[0]?.RMS);
                          }
                          if (singleLyData?.[0]?.REV) {
                            thulyRev =
                              thulyRev + parseInt(singleLyData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.ADR) {
                            thulyAdr =
                              thulyAdr + parseInt(singleLyData?.[0]?.ADR);
                          }

                          if (singleNetStlyData?.[0]?.RMS) {
                            thuNetstlyRms =
                              thuNetstlyRms +
                              parseInt(singleNetStlyData?.[0]?.RMS);
                          }
                          if (singleNetStlyData?.[0]?.REV) {
                            thuNetstlyRev =
                              thuNetstlyRev +
                              parseInt(singleNetStlyData?.[0]?.REV);
                          }
                          if (singleNetStlyData?.[0]?.ADR) {
                            thuNetstlyAdr =
                              thuNetstlyAdr +
                              parseInt(singleNetStlyData?.[0]?.ADR);
                          }

                          if (singleOTBData?.[0]?.LeftToSell) {
                            thuLeftToSell =
                              thuLeftToSell +
                              parseInt(singleOTBData?.[0]?.LeftToSell);
                          }
                          if (singleOTBData?.[0]?.OnTheBook) {
                            thuOtb =
                              thuOtb + parseInt(singleOTBData?.[0]?.OnTheBook);
                          }
                          if (singleOTBData?.[0]?.TotalOCCPercentage) {
                            thuOccPercentage =
                              thuOccPercentage +
                              parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                          }
                          if (singleOTBData?.[0]?.ADR) {
                            thuAdr = thuAdr + parseInt(singleOTBData?.[0]?.ADR);
                          }
                          if (singleOTBData?.[0]?.REV) {
                            thuRev = thuRev + parseInt(singleOTBData?.[0]?.REV);
                          }
                          if (onePickupData?.[0]?.RMS) {
                            thuoneRms =
                              thuoneRms + parseInt(onePickupData?.[0]?.RMS);
                          }
                          if (onePickupData?.[0]?.ADR) {
                            thuoneAdr =
                              thuoneAdr + parseInt(onePickupData?.[0]?.ADR);
                          }
                          if (onePickupData?.[0]?.REV) {
                            thuoneRev =
                              thuoneRev + parseInt(onePickupData?.[0]?.REV);
                          }
                          if (sevenPickupData?.[0]?.RMS) {
                            thusevenRms =
                              thusevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            thusevenAdr =
                              thusevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                          }
                          if (sevenPickupData?.[0]?.REV) {
                            thusevenRev =
                              thusevenRev + parseInt(sevenPickupData?.[0]?.REV);
                          }
                        }
                        if (
                          moment(
                            // new Date(startDateArrayRef.current[index]),
                            new Date(item),
                            "l"
                          ).format("ddd") === "Fri"
                        ) {
                          fritotalDay = fritotalDay + 1;
                          if (onePickupData?.[0]?.ADR) {
                            frioneDayAdrCount = frioneDayAdrCount + 1;
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            frisevenDayAdrCount = frisevenDayAdrCount + 1;
                          }

                          if (singleLyData?.[0]?.RMS) {
                            frilyRms =
                              frilyRms + parseInt(singleLyData?.[0]?.RMS);
                          }
                          if (singleLyData?.[0]?.REV) {
                            frilyRev =
                              frilyRev + parseInt(singleLyData?.[0]?.REV);
                          }
                          if (singleLyData?.[0]?.ADR) {
                            frilyAdr =
                              frilyAdr + parseInt(singleLyData?.[0]?.ADR);
                          }

                          if (singleNetStlyData?.[0]?.RMS) {
                            friNetstlyRms =
                              friNetstlyRms +
                              parseInt(singleNetStlyData?.[0]?.RMS);
                          }
                          if (singleNetStlyData?.[0]?.REV) {
                            friNetstlyRev =
                              friNetstlyRev +
                              parseInt(singleNetStlyData?.[0]?.REV);
                          }
                          if (singleNetStlyData?.[0]?.ADR) {
                            friNetstlyAdr =
                              friNetstlyAdr +
                              parseInt(singleNetStlyData?.[0]?.ADR);
                          }

                          if (singleOTBData?.[0]?.LeftToSell) {
                            friLeftToSell =
                              friLeftToSell +
                              parseInt(singleOTBData?.[0]?.LeftToSell);
                          }
                          if (singleOTBData?.[0]?.OnTheBook) {
                            friOtb =
                              friOtb + parseInt(singleOTBData?.[0]?.OnTheBook);
                          }
                          if (singleOTBData?.[0]?.TotalOCCPercentage) {
                            friOccPercentage =
                              friOccPercentage +
                              parseInt(singleOTBData?.[0]?.TotalOCCPercentage);
                          }
                          if (singleOTBData?.[0]?.ADR) {
                            friAdr = friAdr + parseInt(singleOTBData?.[0]?.ADR);
                          }
                          if (singleOTBData?.[0]?.REV) {
                            friRev = friRev + parseInt(singleOTBData?.[0]?.REV);
                          }
                          if (onePickupData?.[0]?.RMS) {
                            frioneRms =
                              frioneRms + parseInt(onePickupData?.[0]?.RMS);
                          }
                          if (onePickupData?.[0]?.ADR) {
                            frioneAdr =
                              frioneAdr + parseInt(onePickupData?.[0]?.ADR);
                          }
                          if (onePickupData?.[0]?.REV) {
                            frioneRev =
                              frioneRev + parseInt(onePickupData?.[0]?.REV);
                          }
                          if (sevenPickupData?.[0]?.RMS) {
                            frisevenRms =
                              frisevenRms + parseInt(sevenPickupData?.[0]?.RMS);
                          }
                          if (sevenPickupData?.[0]?.ADR) {
                            frisevenAdr =
                              frisevenAdr + parseInt(sevenPickupData?.[0]?.ADR);
                          }
                          if (sevenPickupData?.[0]?.REV) {
                            frisevenRev =
                              frisevenRev + parseInt(sevenPickupData?.[0]?.REV);
                          }
                        }
                      }

                      let isSelfData = rateShopListData?.filter((x) => {
                        if (
                          x.CheckInDate ===
                            // moment(startDateArrayRef.current[index]).format(
                            //   "YYYY-MM-DD"
                            // )
                            moment(item).format("YYYY-MM-DD") &&
                          x.IsSelf === true
                        ) {
                          return x;
                        }
                      });
                      let rateShoptotal = 0;
                      rateShopListData?.map((x) => {
                        if (
                          x.CheckInDate ===
                            // moment(startDateArrayRef.current[index]).format(
                            //   "YYYY-MM-DD"
                            // )
                            moment(item).format("YYYY-MM-DD") &&
                          x.IsSelf === false
                        ) {
                          rateShoptotal = rateShoptotal + parseFloat(x?.Rate);
                        }
                      });
                      let findCompetitorData = rateShopListData?.filter(
                        (x) =>
                          x.CheckInDate ===
                            // moment(startDateArrayRef.current[index]).format(
                            //   "YYYY-MM-DD"
                            // )
                            moment(item).format("YYYY-MM-DD") &&
                          x.IsSelf === false
                      );

                      let findDataForAvgCount = rateShopListData?.filter(
                        (x) =>
                          x.CheckInDate ===
                            // moment(startDateArrayRef.current[index]).format(
                            //   "YYYY-MM-DD"
                            // )
                            moment(item).format("YYYY-MM-DD") &&
                          x.IsSelf === false &&
                          parseFloat(x.Rate) > 0
                      );

                      let avgCompetitor =
                        rateShoptotal > 0 && findCompetitorData?.length > 0
                          ? Math.round(
                              rateShoptotal / findDataForAvgCount?.length
                            )
                          : 0;

                      let dataExistsOrNot = rateShopListData?.filter((x) => {
                        if (
                          x?.CheckInDate ===
                            // moment(startDateArrayRef.current[index]).format(
                            //   "YYYY-MM-DD"
                            // )
                            moment(item).format("YYYY-MM-DD") &&
                          x?.IsSelf === false
                        ) {
                          return x;
                        }
                      });

                      let checkEvent = eventListRef.current?.filter((x) => {
                        let checkingDate = moment(item);
                        let startingDate = moment(x?.startDate);
                        let endingDate = moment(x?.endDate);
                        if (
                          checkingDate.isSameOrAfter(startingDate) &&
                          checkingDate.isSameOrBefore(endingDate)
                        ) {
                          return x;
                        }
                      });

                      return (
                        <React.Fragment key={index}>
                          <tr
                            className={`${
                              dayName ? "backgroundTr" : ""
                            } shadowOnHover`}
                          >
                            <td className="border_right">
                              <div className="d-flex gap-4">
                                {commonService.getDateInFormat(item)}{" "}
                                {moment(new Date(item), "l").format("ddd")}
                                <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                  {checkEvent?.length > 0 && (
                                    <img
                                      style={{ height: "11px", width: "11px" }}
                                      className="iconcolor"
                                      src={ticket}
                                      alt="ticket"
                                      onClick={() => {
                                        selectedEventDateRef.current = item;
                                        eventListPropsRef.current = checkEvent;
                                        setShowTicketModal(true);
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="border_right">
                              <div>
                                {/* <Form.Control
                                type="text"
                                defaultValue={
                                  singleOTBData?.[0]?.UserNotes
                                    ? singleOTBData?.[0]?.UserNotes
                                    : ""
                                }
                                onBlur={(e) => {
                                  let oldUserNote = singleOTBData?.[0]
                                    ?.UserNotes
                                    ? singleOTBData?.[0]?.UserNotes
                                    : "";
                                  if (api !== 0) {
                                    // dateForNoteRef.current = moment(
                                    //   startDateArrayRef.current[index]
                                    // ).format("YYYY-MM-DD");
                                    dateForNoteRef.current =
                                      moment(item).format("YYYY-MM-DD");
                                    userNoteRef.current = e.target.value;
                                    if (oldUserNote !== e.target.value) {
                                      saveNote();
                                    }
                                  }
                                }}
                              /> */}
                                {checkNoteExists?.length > 0 ? (
                                  <img
                                    src={darkNoteIcon}
                                    onClick={() => {
                                      clickedDateForNote.current = item;
                                      setShowNoteSidebar(true);
                                    }}
                                    alt="note"
                                  />
                                ) : (
                                  <img
                                    src={NoteIcon}
                                    onClick={() => {
                                      clickedDateForNote.current = item;
                                      setShowNoteSidebar(true);
                                    }}
                                    alt="note"
                                  />
                                )}
                                <div style={{ display: "none" }}>
                                  {checkNoteExists?.map((item, index) => {
                                    return (
                                      <>
                                        <span>{`${
                                          item?.displayname
                                        } - ${htmlToText(
                                          item?.widgetnotes
                                        )}`}</span>{" "}
                                        <br />
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </td>
                            <td className="text-center">
                              {singleOTBData?.[0]?.LeftToSell
                                ? commonService.formateRoom(
                                    singleOTBData?.[0]?.LeftToSell
                                  )
                                : commonService.formateRoom(0)}
                            </td>
                            <td
                              className="text-center text-primary perbackground 90Outlook-otb"
                              value={singleOTBData?.[0]?.OnTheBook}
                            >
                              <span
                                className={`${
                                  selectedTdIndexRef.current === index
                                    ? "singleDateSummarySpan"
                                    : ""
                                }`}
                                onClick={(e) => {
                                  singleDateSelectedDateRef.current = item;
                                  // startDateArrayRef.current[index];
                                  handleFilterBtnClick(e);
                                  selectedTdIndexRef.current = index;
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {singleOTBData?.[0]?.OnTheBook
                                  ? commonService.formateRoom(
                                      singleOTBData?.[0]?.OnTheBook
                                    )
                                  : 0}
                              </span>
                              {/* {singleOTBData?.[0]?.OnTheBook} */}
                            </td>

                            <td className="text-center">
                              {singleOTBData?.[0]?.TotalOCCPercentage
                                ? singleOTBData?.[0]?.TotalOCCPercentage
                                : 0}
                              %
                            </td>
                            <td
                              className="text-end perbackground perbackground-adr"
                              value={singleOTBData?.[0]?.ADR}
                            >
                              {singleOTBData?.[0]?.ADR
                                ? commonService.formateAmount(
                                    singleOTBData?.[0]?.ADR
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-end border_right">
                              {singleOTBData?.[0]?.REV
                                ? commonService.formateAmount(
                                    singleOTBData?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td
                              className="perbackground perbackground-one-room text-center"
                              value={onePickupData?.[0]?.RMS}
                            >
                              {onePickupData?.[0]?.RMS
                                ? commonService.formateRoom(
                                    onePickupData?.[0]?.RMS
                                  )
                                : commonService.formateRoom(0)}
                            </td>
                            <td className="text-end">
                              {onePickupData?.[0]?.ADR
                                ? commonService.formateAmount(
                                    onePickupData?.[0]?.ADR
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="border_right text-end">
                              {onePickupData?.[0]?.REV
                                ? commonService.formateAmount(
                                    onePickupData?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td
                              className="perbackground perbackground-seven-room text-center"
                              value={sevenPickupData?.[0]?.RMS}
                            >
                              {sevenPickupData?.[0]?.RMS
                                ? commonService.formateRoom(
                                    sevenPickupData?.[0]?.RMS
                                  )
                                : commonService.formateRoom(0)}
                            </td>
                            <td className="text-end">
                              {sevenPickupData?.[0]?.ADR
                                ? commonService.formateAmount(
                                    sevenPickupData?.[0]?.ADR
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="border_right text-end">
                              {sevenPickupData?.[0]?.REV
                                ? commonService.formateAmount(
                                    sevenPickupData?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="border_right text-center">
                              {singleForecastData?.[0]?.RMS
                                ? commonService.formateRoom(
                                    singleForecastData?.[0]?.RMS
                                  )
                                : 0}
                            </td>
                            <td
                              className="text-center perbackground 90OutlookLyRms"
                              value={singleLyData?.[0]?.RMS}
                            >
                              {singleLyData?.[0]?.RMS
                                ? commonService.formateRoom(
                                    singleLyData?.[0]?.RMS
                                  )
                                : 0}
                            </td>
                            <td
                              className="text-end perbackground 90OutlookLyAdr"
                              value={singleLyData?.[0]?.ADR}
                            >
                              {singleLyData?.[0]?.ADR
                                ? commonService.formateAmount(
                                    singleLyData?.[0]?.ADR
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-end border_right">
                              {singleLyData?.[0]?.REV
                                ? commonService.formateAmount(
                                    singleLyData?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td
                              className="text-center perbackground 90OutlookStlyRms"
                              value={singleNetStlyData?.[0]?.RMS}
                            >
                              {singleNetStlyData?.[0]?.RMS
                                ? commonService.formateRoom(
                                    singleNetStlyData?.[0]?.RMS
                                  )
                                : 0}
                            </td>
                            <td
                              className="text-end perbackground 90OutlookStlyAdr"
                              value={singleNetStlyData?.[0]?.ADR}
                            >
                              {singleNetStlyData?.[0]?.ADR
                                ? commonService.formateAmount(
                                    singleNetStlyData?.[0]?.ADR
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            <td className="text-end border_right">
                              {singleNetStlyData?.[0]?.REV
                                ? commonService.formateAmount(
                                    singleNetStlyData?.[0]?.REV
                                  )
                                : commonService.formateAmount(0)}
                            </td>
                            {rateShopListData && (
                              <td className="text-end">
                                {isSelfData?.length > 0
                                  ? `${commonService.formateAmount(
                                      Math.round(isSelfData[0]?.Rate)
                                    )}`
                                  : `${commonService.formateAmount(0)}`}
                              </td>
                            )}
                            {rateShopListData && (
                              <td
                                className="text-end cursorPointer"
                                onClick={() => {
                                  setClickedDate(item);
                                  setToolsSummaryModal(true);
                                }}
                              >
                                {avgCompetitor
                                  ? `${commonService.formateAmount(
                                      avgCompetitor
                                    )}`
                                  : `${commonService.formateAmount(0)}`}
                              </td>
                            )}
                            {rateShopListData && dataExistsOrNot?.length > 0
                              ? rateShopListData?.map((x) => {
                                  if (
                                    x.CheckInDate ===
                                      moment(
                                        // startDateArrayRef.current[index]
                                        item
                                      ).format("YYYY-MM-DD") &&
                                    x.IsSelf === false
                                  ) {
                                    return (
                                      <td className="text-end">
                                        {x?.Rate
                                          ? `${commonService.formateAmount(
                                              Math.round(x?.Rate)
                                            )}`
                                          : `${commonService.formateAmount(0)}`}
                                      </td>
                                    );
                                  }
                                })
                              : rateShopListData?.map((x, index) => {
                                  if (
                                    x.CheckInDate ===
                                      moment(
                                        rateShopListData?.[0]?.CheckInDate
                                      ).format("YYYY-MM-DD") &&
                                    x.IsSelf === false
                                  ) {
                                    return (
                                      <td className="text-end">
                                        {commonService.formateAmount(0)}
                                      </td>
                                    );
                                  }
                                })}
                            {rateShopListData &&
                              totalRateShopRef.current?.length <= 5 &&
                              Array.from(
                                totalRateShopRef.current?.length - 5
                              ).map((item, index) => {
                                return <td key={index}></td>;
                              })}
                            {!rateShopListData && (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            )}
                          </tr>
                          {index === startDateArrayRef.current?.length - 1 && (
                            <>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">WeekDay</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekdayLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekdayOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {weekdayOccPercentage > 0
                                    ? `${parseInt(
                                        weekdayOccPercentage / totalWeekDays
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {weekdayRev > 0 && weekdayOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(weekdayRev / weekdayOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(weekdayRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekdayoneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {weekdayoneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          weekdayoneAdr / weekdayOneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(weekdayoneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekdaysevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {weekdaysevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          weekdaysevenAdr /
                                            weekdaysevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(weekdaysevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(weekdaylyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {weekdaylyRev > 0 && weekdaylyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(weekdaylyRev / weekdaylyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(weekdaylyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(weekdayNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {weekdayNetstlyRev > 0 &&
                                  weekdayNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          weekdayNetstlyRev / weekdayNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(
                                    weekdayNetstlyRev
                                  )}
                                </td>
                                <td
                                  className="boldTd"
                                  colSpan={
                                    totalRateShopRef.current?.length > 5
                                      ? totalRateShopRef.current?.length - 5 + 1
                                      : ""
                                  }
                                ></td>
                                <td className="boldTd text-center">OCC%</td>
                                <td className="boldTd text-center">
                                  Room Nights
                                </td>
                                <td className="boldTd text-end">AdR</td>
                                <td className="boldTd text-end">Revenue</td>
                                <td className="boldTd text-end">RevPar</td>
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">WeekEnd</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekendLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekendOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {weekendOccPercentage > 0
                                    ? `${parseInt(
                                        weekendOccPercentage / totalWeekendDays
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {weekendRev > 0 && weekendOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(weekendRev / weekendOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(weekendRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekendoneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {weekendoneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          weekendoneAdr / weekendOneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(weekendoneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(weekendsevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {weekendsevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          weekendsevenAdr /
                                            weekendsevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(weekendsevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(weekendlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {weekendlyRev > 0 && weekendlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(weekendlyRev / weekendlyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(weekendlyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(weekendNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {weekendNetstlyRev > 0 &&
                                  weekendNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          weekendNetstlyRev / weekendNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(
                                    weekendNetstlyRev
                                  )}
                                </td>

                                <td
                                  className="boldTd"
                                  colSpan={
                                    totalRateShopRef.current?.length > 5
                                      ? totalRateShopRef.current?.length - 5 + 1
                                      : ""
                                  }
                                >
                                  OTB
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.OTB_OCC
                                    ? totalData?.[0]?.OTB_OCC
                                    : 0}
                                  %
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.OTB_RMS
                                    ? commonService.formateRoom(
                                        totalData?.[0]?.OTB_RMS
                                      )
                                    : 0}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.OTB_ADR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.OTB_ADR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.OTB_REV
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.OTB_REV
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.OTB_RevPAR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.OTB_RevPAR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Sun</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(sunLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(sunOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {sunOccPercentage > 0
                                    ? `${parseInt(
                                        sunOccPercentage / suntotalDay
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {sunRev > 0 && sunOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(sunRev / sunOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(sunRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(sunoneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {sunoneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          sunoneAdr / sunoneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(sunoneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(sunsevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {sunsevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          sunsevenAdr / sunsevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(sunsevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(sunlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {sunlyRev > 0 && sunlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(sunlyRev / sunlyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(sunlyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(sunNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {sunNetstlyRev > 0 && sunNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          sunNetstlyRev / sunNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(sunNetstlyRev)}
                                </td>
                                <td
                                  className="boldTd"
                                  colSpan={
                                    totalRateShopRef.current?.length > 5
                                      ? totalRateShopRef.current?.length - 5 + 1
                                      : ""
                                  }
                                >
                                  Budget
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.Budget_OCC
                                    ? totalData?.[0]?.Budget_OCC
                                    : 0}
                                  %
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.Budget_RMS
                                    ? commonService.formateRoom(
                                        totalData?.[0]?.Budget_RMS
                                      )
                                    : 0}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.Budget_ADR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.Budget_ADR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.Budget_REV
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.Budget_REV
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.Budget_RevPAR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.Budget_RevPAR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Mon</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(monLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(monOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {monOccPercentage > 0
                                    ? `${parseInt(
                                        monOccPercentage / montotalDay
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {monRev > 0 && monOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(monRev / monOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(monRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(mononeRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {mononeAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          mononeAdr / mononeDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(mononeRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(monsevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {monsevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          monsevenAdr / monsevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(monsevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(monlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {monlyRev > 0 && monlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(monlyRev / monlyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(monlyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(monNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {monNetstlyRev > 0 && monNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          monNetstlyRev / monNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(monNetstlyRev)}
                                </td>
                                <td
                                  className="boldTd"
                                  colSpan={
                                    totalRateShopRef.current?.length > 5
                                      ? totalRateShopRef.current?.length - 5 + 1
                                      : ""
                                  }
                                >
                                  ForeCast
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.Forecast_OCC
                                    ? totalData?.[0]?.Forecast_OCC
                                    : 0}
                                  %
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.Forecast_RMS
                                    ? commonService.formateRoom(
                                        totalData?.[0]?.Forecast_RMS
                                      )
                                    : 0}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.Forecast_ADR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.Forecast_ADR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.Forecast_REV
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.Forecast_REV
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.Forecast_RevPAR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.Forecast_RevPAR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Tue</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(tueLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(tueOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {tueOccPercentage > 0
                                    ? `${parseInt(
                                        tueOccPercentage / tuetotalDay
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {tueRev > 0 && tueOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(tueRev / tueOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(tueRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(tueoneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {tueoneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          tueoneAdr / tueoneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(tueoneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(tuesevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {tuesevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          tuesevenAdr / tuesevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(tuesevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(tuelyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {tuelyRev > 0 && tuelyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(tuelyRev / tuelyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(tuelyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(tueNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {tueNetstlyRev > 0 && tueNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          tueNetstlyRev / tueNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(tueNetstlyRev)}
                                </td>
                                <td
                                  className="boldTd"
                                  colSpan={
                                    totalRateShopRef.current?.length > 5
                                      ? totalRateShopRef.current?.length - 5 + 1
                                      : ""
                                  }
                                >
                                  LY Actuals
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.LY_Actuals_OCC
                                    ? totalData?.[0]?.LY_Actuals_OCC
                                    : 0}
                                  %
                                </td>
                                <td className="boldTd text-center">
                                  {totalData?.[0]?.LY_Actuals_RMS
                                    ? commonService.formateRoom(
                                        totalData?.[0]?.LY_Actuals_RMS
                                      )
                                    : 0}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.LY_Actuals_ADR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.LY_Actuals_ADR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.LY_Actuals_REV
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.LY_Actuals_REV
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end">
                                  {totalData?.[0]?.LY_Actuals_RevPAR
                                    ? commonService.formateAmount(
                                        totalData?.[0]?.LY_Actuals_RevPAR
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Wed</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(wedLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(wedOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {wedOccPercentage > 0
                                    ? `${parseInt(
                                        wedOccPercentage / wedtotalDay
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {wedRev > 0 && wedOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(wedRev / wedOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(wedRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(wedoneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {wedoneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          wedoneAdr / wedoneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(wedoneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(wedsevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {wedsevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          wedsevenAdr / wedsevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(wedsevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(wedlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {wedlyRev > 0 && wedlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(wedlyRev / wedlyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(wedlyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(wedNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {wedNetstlyRev > 0 && wedNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          wedNetstlyRev / wedNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(wedNetstlyRev)}
                                </td>
                                <td
                                  className="boldTd"
                                  colSpan={
                                    totalRateShopRef.current?.length > 5
                                      ? totalRateShopRef.current?.length - 5 + 1
                                      : ""
                                  }
                                >
                                  OTB VS Budget
                                </td>
                                <td className="boldTd text-center">
                                  {(totalData?.[0]?.OTB_OCC
                                    ? totalData?.[0]?.OTB_OCC
                                    : 0) -
                                    (totalData?.[0]?.Budget_OCC
                                      ? totalData?.[0]?.Budget_OCC
                                      : 0)}
                                  %
                                </td>
                                <td className="boldTd text-center">
                                  {(totalData?.[0]?.OTB_RMS
                                    ? totalData?.[0]?.OTB_RMS
                                    : 0) -
                                    (totalData?.[0]?.Budget_RMS
                                      ? totalData?.[0]?.Budget_RMS
                                      : 0)}
                                </td>
                                <td className="boldTd text-end">
                                  {commonService.formateAmount(
                                    (totalData?.[0]?.OTB_ADR
                                      ? totalData?.[0]?.OTB_ADR
                                      : 0) -
                                      (totalData?.[0]?.Budget_ADR
                                        ? totalData?.[0]?.Budget_ADR
                                        : 0)
                                  )}
                                </td>
                                <td className="boldTd text-end">
                                  {commonService.formateAmount(
                                    (totalData?.[0]?.OTB_REV
                                      ? totalData?.[0]?.OTB_REV
                                      : 0) -
                                      (totalData?.[0]?.Budget_REV
                                        ? totalData?.[0]?.Budget_REV
                                        : 0)
                                  )}
                                </td>
                                <td className="boldTd text-end">
                                  {commonService.formateAmount(
                                    (totalData?.[0]?.OTB_RevPAR
                                      ? totalData?.[0]?.OTB_RevPAR
                                      : 0) -
                                      (totalData?.[0]?.Budget_RevPAR
                                        ? totalData?.[0]?.Budget_RevPAR
                                        : 0)
                                  )}
                                </td>
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Thu</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(thuLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(thuOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {thuOccPercentage > 0
                                    ? `${parseInt(
                                        thuOccPercentage / thutotalDay
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {thuRev > 0 && thuOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(thuRev / thuOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(thuRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(thuoneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {thuoneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          thuoneAdr / thuoneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(thuoneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(thusevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {thusevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          thusevenAdr / thusevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(thusevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(thulyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {thulyRev > 0 && thulyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(thulyRev / thulyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(thulyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(thuNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {thuNetstlyRev > 0 && thuNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          thuNetstlyRev / thuNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(thuNetstlyRev)}
                                </td>
                                <td
                                  className="boldTd"
                                  colSpan={
                                    totalRateShopRef.current?.length > 5
                                      ? totalRateShopRef.current?.length - 5 + 1
                                      : ""
                                  }
                                >
                                  OTB VS LY
                                </td>
                                <td className="boldTd text-center">
                                  {(totalData?.[0]?.OTB_OCC
                                    ? totalData?.[0]?.OTB_OCC
                                    : 0) -
                                    (totalData?.[0]?.LY_Actuals_OCC
                                      ? totalData?.[0]?.LY_Actuals_OCC
                                      : 0)}
                                  %
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(
                                    (totalData?.[0]?.OTB_RMS
                                      ? totalData?.[0]?.OTB_RMS
                                      : 0) -
                                      (totalData?.[0]?.LY_Actuals_RMS
                                        ? totalData?.[0]?.LY_Actuals_RMS
                                        : 0)
                                  )}
                                </td>
                                <td className="boldTd text-end">
                                  {commonService.formateAmount(
                                    (totalData?.[0]?.OTB_ADR
                                      ? totalData?.[0]?.OTB_ADR
                                      : 0) -
                                      (totalData?.[0]?.LY_Actuals_ADR
                                        ? totalData?.[0]?.LY_Actuals_ADR
                                        : 0)
                                  )}
                                </td>
                                <td className="boldTd text-end">
                                  {commonService.formateAmount(
                                    (totalData?.[0]?.OTB_REV
                                      ? totalData?.[0]?.OTB_REV
                                      : 0) -
                                      (totalData?.[0]?.LY_Actuals_REV
                                        ? totalData?.[0]?.LY_Actuals_REV
                                        : 0)
                                  )}
                                </td>
                                <td className="boldTd text-end">
                                  {commonService.formateAmount(
                                    (totalData?.[0]?.OTB_RevPAR
                                      ? totalData?.[0]?.OTB_RevPAR
                                      : 0) -
                                      (totalData?.[0]?.LY_Actuals_RevPAR
                                        ? totalData?.[0]?.LY_Actuals_RevPAR
                                        : 0)
                                  )}
                                </td>
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Fri</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(friLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(friOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {friOccPercentage > 0
                                    ? `${parseInt(
                                        friOccPercentage / fritotalDay
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {friRev > 0 && friOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(friRev / friOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(friRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(frioneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {frioneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          frioneAdr / frioneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(frioneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(frisevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {frisevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          frisevenAdr / frisevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(frisevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(frilyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {frilyRev > 0 && frilyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(frilyRev / frilyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(frilyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(friNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {friNetstlyRev > 0 && friNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          friNetstlyRev / friNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(friNetstlyRev)}
                                </td>
                                {rateShopListData &&
                                  rateShopListData?.map((x) => {
                                    if (
                                      x.CheckInDate ===
                                        moment(
                                          rateShopListData?.[0]?.CheckInDate
                                        ).format("YYYY-MM-DD") &&
                                      x.IsSelf === true
                                    ) {
                                      return <td className="boldTd"></td>;
                                    }
                                  })}
                                <td className="boldTd"></td>
                                {rateShopListData &&
                                  rateShopListData?.map(
                                    (x, indexpfRateshop) => {
                                      if (
                                        x.CheckInDate ===
                                          moment(
                                            rateShopListData?.[0]?.CheckInDate
                                          ).format("YYYY-MM-DD") &&
                                        x.IsSelf === false
                                      ) {
                                        return <td className="boldTd"></td>;
                                      }
                                    }
                                  )}
                                {!rateShopListData && (
                                  <>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                  </>
                                )}
                              </tr>
                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Sat</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(satLeftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(satOtb)}
                                </td>
                                <td className="boldTd text-center">
                                  {satOccPercentage > 0
                                    ? `${parseInt(
                                        satOccPercentage / sattotalDay
                                      )}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {satRev > 0 && satOtb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(satRev / satOtb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(satRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(satoneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {satoneAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          satoneAdr / satoneDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(satoneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(satsevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {satsevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(
                                          satsevenAdr / satsevenDayAdrCount
                                        )
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(satsevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(satlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {satlyRev > 0 && satlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(satlyRev / satlyRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(satlyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(satNetstlyRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {satNetstlyRev > 0 && satNetstlyRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          satNetstlyRev / satNetstlyRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(satNetstlyRev)}
                                </td>
                                {rateShopListData &&
                                  rateShopListData?.map((x) => {
                                    if (
                                      x.CheckInDate ===
                                        moment(
                                          rateShopListData?.[0]?.CheckInDate
                                        ).format("YYYY-MM-DD") &&
                                      x.IsSelf === true
                                    ) {
                                      return <td className="boldTd"></td>;
                                    }
                                  })}
                                <td className="boldTd"></td>
                                {rateShopListData &&
                                  rateShopListData?.map(
                                    (x, indexpfRateshop) => {
                                      if (
                                        x.CheckInDate ===
                                          moment(
                                            rateShopListData?.[0]?.CheckInDate
                                          ).format("YYYY-MM-DD") &&
                                        x.IsSelf === false
                                      ) {
                                        return <td className="boldTd"></td>;
                                      }
                                    }
                                  )}
                                {!rateShopListData && (
                                  <>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                  </>
                                )}
                              </tr>

                              <tr className="shadowOnHover">
                                <td className="boldTd border_right">Total</td>
                                <td className="boldTd border_right"></td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(leftToSell)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(otb)}
                                </td>
                                <td className="boldTd text-center">
                                  {occPercentage > 0
                                    ? `${parseInt(occPercentage / diffDays)}%`
                                    : `0%`}
                                </td>
                                <td className="boldTd text-end">
                                  {rev > 0 && otb > 0
                                    ? `${commonService.formateAmount(
                                        parseInt(rev / otb)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(rev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(oneRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {oneAdr
                                    ? commonService.formateAmount(
                                        Math.round(oneAdr / onedayAdrCount)
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(oneRev)}
                                </td>
                                <td className="boldTd text-center">
                                  {commonService.formateRoom(sevenRms)}
                                </td>
                                <td className="boldTd text-end">
                                  {sevenAdr
                                    ? commonService.formateAmount(
                                        Math.round(sevenAdr / sevendayAdrCount)
                                      )
                                    : commonService.formateAmount(0)}
                                </td>
                                <td className="boldTd text-end border_right">
                                  {commonService.formateAmount(sevenRev)}
                                </td>
                                <td className="boldTd border_right"></td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(lytotalRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {lytotallyRev > 0 && lytotalRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(lytotallyRev / lytotalRms)
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end border_right boldTd">
                                  {commonService.formateAmount(lytotallyRev)}
                                </td>
                                <td className="text-center boldTd">
                                  {commonService.formateRoom(netStlyTotalRms)}
                                </td>
                                <td className="text-end boldTd">
                                  {netStlyTotalRev > 0 && netStlyTotalRms > 0
                                    ? `${commonService.formateAmount(
                                        Math.round(
                                          netStlyTotalRev / netStlyTotalRms
                                        )
                                      )}`
                                    : `${commonService.formateAmount(0)}`}
                                </td>
                                <td className="text-end boldTd border_right">
                                  {commonService.formateAmount(netStlyTotalRev)}
                                </td>
                                {rateShopListData &&
                                  rateShopListData?.map((x) => {
                                    if (
                                      x.CheckInDate ===
                                        moment(
                                          rateShopListData?.[0]?.CheckInDate
                                        ).format("YYYY-MM-DD") &&
                                      x.IsSelf === true
                                    ) {
                                      return <td className="boldTd"></td>;
                                    }
                                  })}
                                <td className="boldTd"></td>
                                {rateShopListData &&
                                  rateShopListData?.map(
                                    (x, indexpfRateshop) => {
                                      if (
                                        x.CheckInDate ===
                                          moment(
                                            rateShopListData?.[0]?.CheckInDate
                                          ).format("YYYY-MM-DD") &&
                                        x.IsSelf === false
                                      ) {
                                        return <td className="boldTd"></td>;
                                      }
                                    }
                                  )}
                                {!rateShopListData && (
                                  <>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                    <td className="boldTd"></td>
                                  </>
                                )}
                              </tr>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      <div className="ninentyOutlook-drop-widget-container">
        {api !== 0 && (
          <div className="gap-1 ninentyOutlook-drop-widget-container-menu">
            {/* <Button className="heading-menu-btns" type="button">
              <img
                src={NoteIcon}
                alt="menuIcon"
                onClick={() => {
                  clickedDateForNote.current = "";
                  setShowNoteSidebar(true);
                }}
              />
            </Button> */}
            <Button
              className="heading-menu-btns"
              type="button"
              title="Send Mail"
            >
              <img
                src={emailIcon}
                alt="emailIcon"
                onClick={() => {
                  if (
                    window.location.href.includes("RevPAK%20Summary") ||
                    window.location.href.includes("90%20Day%20Outlook")
                  ) {
                    handleExport(
                      "ninentyOutlook-drop-widget-container",
                      "NinentyDayOutlookCommon",
                      "EMAIL"
                    );
                  } else {
                    commonService.fnSendWidgetMail(
                      tblids.ninentydayDropdown,
                      "90 Days Outlook",
                      snapshotId,
                      defaultAsOfDateFromRTK,
                      "",
                      para?.widgetId
                    );
                  }
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={infoIcon}
                alt="info"
                onClick={() => {
                  widgetDetailRef.current = {
                    widgetdetails: "",
                    usernote: "",
                    compName: compName?.ninetyDayOutlook,
                    widgetId: para?.widgetId,
                  };
                  setWidgetModal(true);
                }}
              />
            </Button>
            <Button className="heading-menu-btns" type="button">
              <img
                src={downloadIcon}
                alt="menuIcon"
                import={tblids.ninentydayDropdown}
                onClick={() => {
                  if (
                    window.location.href.includes("RevPAK%20Summary") ||
                    window.location.href.includes("90%20Day%20Outlook")
                  ) {
                    handleExport(
                      "ninentyOutlook-drop-widget-container",
                      "NinentyDayOutlookCommon",
                      "DOWNLOAD"
                    );
                  } else {
                    commonService.fnExportInCSV(
                      tblids.ninentydayDropdown,
                      // "90 Days Outlook"
                      `${para?.propertyCode}-90 Days Outlook-${moment(
                        new Date()
                      ).format("MMDDYYYY")}`
                    );
                  }
                }}
              />
            </Button>
          </div>
        )}
        <div className="ViewPropertySetupTable mt-3">
          <div className="table__container">
            <div className="stripped__table">
              <Table id={tblids.ninentydayDropdown} responsive>
                <thead>
                  <tr>
                    <th className="border_right text-center"></th>
                    <th colSpan={4} className="border_right text-center">
                      {`${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(startDate)
                      )} - ${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(endDate)
                      )} as of ${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(para?.asOfDate)
                      )}`}
                    </th>
                    <th colSpan={4} className="border_right text-center">
                      {`${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(rightStartDate)
                      )} - ${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(rightEndDate)
                      )} as of ${commonService.getDateInFormat(
                        commonService.convertDateInTimezone(para?.asOfDate)
                      )}`}
                    </th>
                    <th colSpan={4} className="text-center">
                      Variance
                    </th>
                  </tr>
                  <tr>
                    <th className="border_right"></th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="border_right text-center">
                      Rev Contribution
                    </th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="border_right text-center">
                      Rev Contribution
                    </th>
                    <th className="text-center">Room Nights</th>
                    <th className="text-end">ADR</th>
                    <th className="text-end">Revenue</th>
                    <th className="text-center">Rev Contribution</th>
                  </tr>
                </thead>
                <tbody>
                  {dropDownLoading ? (
                    <tr style={{ height: "80px", position: "relative" }}>
                      <Loader />
                    </tr>
                  ) : (
                    segmentDrilldownData &&
                    segmentDrilldownData?.map((item, index) => {
                      let msLeftRevContri = 0,
                        msRightRevContri = 0;
                      if (item?.left_REV > 0 && totalRef.current?.leftRev > 0) {
                        msLeftRevContri = (
                          (parseInt(item?.left_REV) * 100) /
                          totalRef.current?.leftRev
                        )?.toFixed(1);
                      }

                      if (
                        item?.right_REV > 0 &&
                        totalRef.current?.rightRev > 0
                      ) {
                        msRightRevContri = (
                          (parseInt(item?.right_REV) * 100) /
                          totalRef.current?.rightRev
                        )?.toFixed(1);
                      }
                      return (
                        <>
                          <tr id={item?.guid} className="shadowOnHover">
                            <td className="border_right">
                              <div
                                className={`d-flex gap-2`}
                                onClick={() => {
                                  let findSelectedMarketSegment =
                                    calledMarketSegmentRef.current?.filter(
                                      (x) => x === item?.name
                                    );
                                  if (findSelectedMarketSegment?.length > 0) {
                                    const classList =
                                      document.getElementsByClassName(
                                        item?.guid
                                      );

                                    if (
                                      classList?.[0]?.classList?.contains(
                                        "nestedTableRowNone"
                                      )
                                    ) {
                                      handleShowHideRow(item?.guid);
                                    } else {
                                      handleShowHideRow(item?.guid, item);
                                    }
                                  } else {
                                    if (!msLoading) {
                                      selectedMarketSegmentRef.current =
                                        item?.name;
                                      clickedTrRef.current = [item?.guid];
                                      getRateCodeData();
                                    }
                                  }
                                }}
                              >
                                <p>{item?.name}</p>
                                <img src={downArrow} />
                                {msLoading &&
                                  selectedMarketSegmentRef.current ===
                                    item?.name && (
                                    <div
                                      class="spinner-border text-primary ms-2"
                                      role="status"
                                      style={{
                                        height: "13px",
                                        width: "13px",
                                        color: "#3565FC",
                                      }}
                                    >
                                      <span class="sr-only"></span>
                                    </div>
                                  )}
                              </div>
                            </td>
                            <td className="text-center">
                              {item?.left_RMS
                                ? commonService.formateRoom(item?.left_RMS)
                                : 0}
                            </td>
                            <td className="text-end">
                              {item?.left_ADR
                                ? `${commonService.formateAmount(
                                    item?.left_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="text-end">
                              {item?.left_REV
                                ? `${commonService.formateAmount(
                                    item?.left_REV
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="border_right text-center">
                              {msLeftRevContri}%
                            </td>
                            <td className="text-center">
                              {item?.right_RMS
                                ? commonService.formateRoom(item?.right_RMS)
                                : 0}
                            </td>
                            <td className="text-end">
                              {item?.right_ADR
                                ? `${commonService.formateAmount(
                                    item?.right_ADR
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="text-end">
                              {item?.right_REV
                                ? `${commonService.formateAmount(
                                    item?.right_REV
                                  )}`
                                : `${commonService.formateAmount(0)}`}
                            </td>
                            <td className="border_right text-center">
                              {msRightRevContri}%
                            </td>
                            <td
                              className={`text-center ${
                                item?.varientRms < 0 ? "redText" : ""
                              }`}
                            >
                              {commonService.formateRoom(item?.varientRms)}
                            </td>
                            <td
                              className={`text-end ${
                                item?.varientAdr < 0 ? "redText" : ""
                              }`}
                            >
                              {item?.varientAdr
                                ? commonService.formateAmount(item?.varientAdr)
                                : commonService.formateAmount(0)}
                            </td>
                            <td
                              className={`text-end ${
                                item?.varientRev < 0 ? "redText" : ""
                              }`}
                            >
                              {item?.varientRev
                                ? commonService.formateAmount(item?.varientRev)
                                : commonService.formateAmount(0)}
                            </td>
                            <td
                              className={`text-center ${
                                (msLeftRevContri - msRightRevContri)?.toFixed(
                                  1
                                ) < 0
                                  ? "redText"
                                  : ""
                              }`}
                            >
                              {(msLeftRevContri - msRightRevContri)?.toFixed(1)}
                              %
                            </td>
                          </tr>
                          {Array.isArray(item?.items) &&
                            item?.items?.length > 0 &&
                            item?.items?.map((rateCodeItem, rateCodeIndex) => {
                              let rateCodeLeftRevContri = 0,
                                rateCodeRightRevContri = 0;
                              if (
                                rateCodeItem?.left_REV > 0 &&
                                totalRef.current?.leftRev > 0
                              ) {
                                rateCodeLeftRevContri = (
                                  (parseInt(rateCodeItem?.left_REV) * 100) /
                                  totalRef.current?.leftRev
                                )?.toFixed(1);
                              }

                              if (
                                rateCodeItem?.right_REV > 0 &&
                                totalRef.current?.rightRev > 0
                              ) {
                                rateCodeRightRevContri = (
                                  (parseInt(rateCodeItem?.right_REV) * 100) /
                                  totalRef.current?.rightRev
                                )?.toFixed(1);
                              }
                              return (
                                <>
                                  <tr
                                    className={`${item?.guid} nestedTableRowNone shadowOnHover`}
                                    id={rateCodeItem?.guid}
                                  >
                                    <td className="border_right">
                                      <div
                                        style={{ paddingLeft: "10px" }}
                                        className="d-flex gap-2"
                                        onClick={() => {
                                          if (!rateCodeLoading) {
                                            selectedMarketSegmentRef.current =
                                              item?.name;
                                            selectedRateCodeRef.current =
                                              rateCodeItem?.name;
                                          }
                                          let findSelectedRateCode =
                                            calledRateCodeRef.current?.filter(
                                              (x) =>
                                                x?.ms ===
                                                  selectedMarketSegmentRef.current &&
                                                x?.rc ===
                                                  selectedRateCodeRef.current
                                            );

                                          if (
                                            findSelectedRateCode?.length > 0
                                          ) {
                                            const classList =
                                              document.getElementsByClassName(
                                                rateCodeItem?.guid
                                              );

                                            if (
                                              classList?.[0]?.classList?.contains(
                                                "nestedTableRowNone"
                                              )
                                            ) {
                                              handleShowHideRow(
                                                rateCodeItem?.guid
                                              );
                                            } else {
                                              handleShowHideRow(
                                                rateCodeItem?.guid,
                                                rateCodeItem
                                              );
                                            }
                                          } else {
                                            if (!rateCodeLoading) {
                                              clickedTrRef.current = [
                                                ...clickedTrRef.current,
                                                rateCodeItem?.guid,
                                              ];
                                              getCompanyData();
                                            }
                                          }
                                        }}
                                      >
                                        <p>{rateCodeItem?.name}</p>
                                        <img src={downArrow} />
                                        {rateCodeLoading &&
                                          selectedMarketSegmentRef.current ===
                                            item?.name &&
                                          selectedRateCodeRef.current ===
                                            rateCodeItem?.name && (
                                            <div
                                              class="spinner-border text-primary ms-2"
                                              role="status"
                                              style={{
                                                height: "13px",
                                                width: "13px",
                                                color: "#3565FC",
                                              }}
                                            >
                                              <span class="sr-only"></span>
                                            </div>
                                          )}
                                      </div>
                                    </td>

                                    <td className="text-center">
                                      {rateCodeItem?.left_RMS
                                        ? commonService.formateRoom(
                                            rateCodeItem?.left_RMS
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.left_ADR
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.left_ADR
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.left_REV
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.left_REV
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="border_right text-center">
                                      {rateCodeLeftRevContri}%
                                    </td>
                                    <td className="text-center">
                                      {rateCodeItem?.right_RMS
                                        ? commonService.formateRoom(
                                            rateCodeItem?.right_RMS
                                          )
                                        : 0}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.right_ADR
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.right_ADR
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="text-end">
                                      {rateCodeItem?.right_REV
                                        ? `${commonService.formateAmount(
                                            rateCodeItem?.right_REV
                                          )}`
                                        : `${commonService.formateAmount(0)}`}
                                    </td>
                                    <td className="border_right text-center">
                                      {rateCodeRightRevContri}%
                                    </td>
                                    <td
                                      className={`text-center ${
                                        rateCodeItem?.varientRms < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {commonService.formateRoom(
                                        rateCodeItem?.varientRms
                                      )}
                                    </td>
                                    <td
                                      className={`text-end ${
                                        rateCodeItem?.varientAdr < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {rateCodeItem?.varientAdr
                                        ? commonService.formateAmount(
                                            rateCodeItem?.varientAdr
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td
                                      className={`text-end ${
                                        rateCodeItem?.varientRev < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {rateCodeItem?.varientRev
                                        ? commonService.formateAmount(
                                            rateCodeItem?.varientRev
                                          )
                                        : commonService.formateAmount(0)}
                                    </td>
                                    <td
                                      className={`text-center ${
                                        (
                                          rateCodeLeftRevContri -
                                          rateCodeRightRevContri
                                        )?.toFixed(1) < 0
                                          ? "redText"
                                          : ""
                                      }`}
                                    >
                                      {(
                                        rateCodeLeftRevContri -
                                        rateCodeRightRevContri
                                      )?.toFixed(1)}
                                      %
                                    </td>
                                  </tr>
                                  {Array.isArray(rateCodeItem?.items) &&
                                    rateCodeItem?.items?.length > 0 &&
                                    rateCodeItem?.items?.map(
                                      (companyItem, companyIndex) => {
                                        let companyItemLeftRevContri = 0,
                                          companyItemRightRevContri = 0;
                                        if (
                                          companyItem?.left_REV > 0 &&
                                          totalRef.current?.leftRev > 0
                                        ) {
                                          companyItemLeftRevContri = (
                                            (parseInt(companyItem?.left_REV) *
                                              100) /
                                            totalRef.current?.leftRev
                                          )?.toFixed(1);
                                        }

                                        if (
                                          companyItem?.right_REV > 0 &&
                                          totalRef.current?.rightRev > 0
                                        ) {
                                          companyItemRightRevContri = (
                                            (parseInt(companyItem?.right_REV) *
                                              100) /
                                            totalRef.current?.rightRev
                                          )?.toFixed(1);
                                        }
                                        return (
                                          <>
                                            <tr
                                              className={`${rateCodeItem?.guid} nestedTableRowNone shadowOnHover`}
                                              id={companyItem?.guid}
                                            >
                                              <td className="border_right">
                                                <div
                                                  style={{
                                                    paddingLeft: "20px",
                                                  }}
                                                  className="d-flex gap-2"
                                                  onClick={() => {
                                                    if (!companyLoading) {
                                                      selectedMarketSegmentRef.current =
                                                        item?.name;
                                                      selectedRateCodeRef.current =
                                                        rateCodeItem?.name;
                                                      selectedCompanyRef.current =
                                                        companyItem?.name;
                                                    }
                                                    let findSelectedCompany =
                                                      calledCompanyRef.current?.filter(
                                                        (x) =>
                                                          x?.ms ===
                                                            selectedMarketSegmentRef.current &&
                                                          x?.rc ===
                                                            selectedRateCodeRef.current &&
                                                          x?.company ===
                                                            selectedCompanyRef.current
                                                      );

                                                    if (
                                                      findSelectedCompany?.length >
                                                      0
                                                    ) {
                                                      const classList =
                                                        document.getElementsByClassName(
                                                          companyItem?.guid
                                                        );

                                                      if (
                                                        classList?.[0]?.classList?.contains(
                                                          "nestedTableRowNone"
                                                        )
                                                      ) {
                                                        handleShowHideRow(
                                                          companyItem?.guid
                                                        );
                                                      } else {
                                                        handleShowHideRow(
                                                          companyItem?.guid,
                                                          companyItem
                                                        );
                                                      }
                                                    } else {
                                                      if (!companyLoading) {
                                                        clickedTrRef.current = [
                                                          ...clickedTrRef.current,
                                                          companyItem?.guid,
                                                        ];
                                                        getGuestData();
                                                      }
                                                    }
                                                  }}
                                                >
                                                  <p>{companyItem?.name}</p>
                                                  <img src={downArrow} />
                                                  {companyLoading &&
                                                    selectedMarketSegmentRef.current ===
                                                      item?.name &&
                                                    selectedRateCodeRef.current ===
                                                      rateCodeItem?.name &&
                                                    selectedCompanyRef.current ===
                                                      companyItem?.name && (
                                                      <div
                                                        class="spinner-border text-primary ms-2"
                                                        role="status"
                                                        style={{
                                                          height: "13px",
                                                          width: "13px",
                                                          color: "#3565FC",
                                                        }}
                                                      >
                                                        <span class="sr-only"></span>
                                                      </div>
                                                    )}
                                                </div>
                                              </td>

                                              <td className="text-center">
                                                {companyItem?.left_RMS
                                                  ? commonService.formateRoom(
                                                      companyItem?.left_RMS
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.left_ADR
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.left_ADR
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.left_REV
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.left_REV
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="border_right text-center">
                                                {companyItemLeftRevContri}%
                                              </td>
                                              <td className="text-center">
                                                {companyItem?.right_RMS
                                                  ? commonService.formateRoom(
                                                      companyItem?.right_RMS
                                                    )
                                                  : 0}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.right_ADR
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.right_ADR
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="text-end">
                                                {companyItem?.right_REV
                                                  ? `${commonService.formateAmount(
                                                      companyItem?.right_REV
                                                    )}`
                                                  : `${commonService.formateAmount(
                                                      0
                                                    )}`}
                                              </td>
                                              <td className="border_right text-center">
                                                {companyItemRightRevContri}%
                                              </td>
                                              <td
                                                className={`text-center ${
                                                  companyItem?.varientRms < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {commonService.formateRoom(
                                                  companyItem?.varientRms
                                                )}
                                              </td>
                                              <td
                                                className={`text-end ${
                                                  companyItem?.varientAdr < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {companyItem?.varientAdr
                                                  ? commonService.formateAmount(
                                                      companyItem?.varientAdr
                                                    )
                                                  : commonService.formateAmount(
                                                      0
                                                    )}
                                              </td>
                                              <td
                                                className={`text-end ${
                                                  companyItem?.varientRev < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {companyItem?.varientRev
                                                  ? commonService.formateAmount(
                                                      companyItem?.varientRev
                                                    )
                                                  : commonService.formateAmount(
                                                      0
                                                    )}
                                              </td>
                                              <td
                                                className={`text-center ${
                                                  (
                                                    companyItemLeftRevContri -
                                                    companyItemRightRevContri
                                                  )?.toFixed(1) < 0
                                                    ? "redText"
                                                    : ""
                                                }`}
                                              >
                                                {(
                                                  companyItemLeftRevContri -
                                                  companyItemRightRevContri
                                                )?.toFixed(1)}
                                                %
                                              </td>
                                            </tr>
                                            {Array.isArray(
                                              companyItem?.items
                                            ) &&
                                              companyItem?.items?.length > 0 &&
                                              companyItem?.items?.map(
                                                (guestItem, guestIndex) => {
                                                  let guestLeftRevContri = 0,
                                                    guestRightRevContri = 0;
                                                  if (
                                                    guestItem?.left_REV > 0 &&
                                                    totalRef.current?.leftRev >
                                                      0
                                                  ) {
                                                    guestLeftRevContri = (
                                                      (parseInt(
                                                        guestItem?.left_REV
                                                      ) *
                                                        100) /
                                                      totalRef.current?.leftRev
                                                    )?.toFixed(1);
                                                  }

                                                  if (
                                                    guestItem?.right_REV > 0 &&
                                                    totalRef.current?.rightRev >
                                                      0
                                                  ) {
                                                    guestRightRevContri = (
                                                      (parseInt(
                                                        guestItem?.right_REV
                                                      ) *
                                                        100) /
                                                      totalRef.current?.rightRev
                                                    )?.toFixed(1);
                                                  }
                                                  return (
                                                    <tr
                                                      className={`${companyItem?.guid} nestedTableRowNone shadowOnHover`}
                                                      onClick={(e) => {
                                                        checkActiveRow(e);
                                                      }}
                                                    >
                                                      <td className="border_right">
                                                        <div
                                                          style={{
                                                            paddingLeft: "30px",
                                                          }}
                                                          className="d-flex gap-2"
                                                        >
                                                          <p>
                                                            {guestItem?.name}
                                                          </p>
                                                        </div>
                                                      </td>
                                                      <td className="text-center">
                                                        {guestItem?.left_RMS
                                                          ? commonService.formateRoom(
                                                              guestItem?.left_RMS
                                                            )
                                                          : 0}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.left_ADR
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.left_ADR
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.left_REV
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.left_REV
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="border_right text-center">
                                                        {guestLeftRevContri}%
                                                      </td>
                                                      <td className="text-center">
                                                        {guestItem?.right_RMS
                                                          ? commonService.formateRoom(
                                                              guestItem?.right_RMS
                                                            )
                                                          : 0}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.right_ADR
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.right_ADR
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="text-end">
                                                        {guestItem?.right_REV
                                                          ? `${commonService.formateAmount(
                                                              guestItem?.right_REV
                                                            )}`
                                                          : `${commonService.formateAmount(
                                                              0
                                                            )}`}
                                                      </td>
                                                      <td className="border_right text-center">
                                                        {guestRightRevContri}%
                                                      </td>
                                                      <td
                                                        className={`text-center ${
                                                          guestItem?.varientRms <
                                                          0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {commonService.formateRoom(
                                                          guestItem?.varientRms
                                                        )}
                                                      </td>
                                                      <td
                                                        className={`text-end ${
                                                          guestItem?.varientAdr <
                                                          0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {guestItem?.varientAdr
                                                          ? commonService.formateAmount(
                                                              guestItem?.varientAdr
                                                            )
                                                          : commonService.formateAmount(
                                                              0
                                                            )}
                                                      </td>
                                                      <td
                                                        className={`text-end ${
                                                          guestItem?.varientRev <
                                                          0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {guestItem?.varientRev
                                                          ? commonService.formateAmount(
                                                              guestItem?.varientRev
                                                            )
                                                          : commonService.formateAmount(
                                                              0
                                                            )}
                                                      </td>
                                                      <td
                                                        className={`text-center ${
                                                          (
                                                            guestLeftRevContri -
                                                            guestRightRevContri
                                                          )?.toFixed(1) < 0
                                                            ? "redText"
                                                            : ""
                                                        }`}
                                                      >
                                                        {(
                                                          guestLeftRevContri -
                                                          guestRightRevContri
                                                        )?.toFixed(1)}
                                                        %
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </>
                                        );
                                      }
                                    )}
                                </>
                              );
                            })}
                          {index === segmentDrilldownData?.length - 1 && (
                            <tr>
                              <td className="border_right boldTd">Totals</td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  totalRef.current.leftRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                {totalRef.current.leftRoom > 0 &&
                                totalRef.current.leftRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.leftRev /
                                          totalRef.current.leftRoom
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  totalRef.current.leftRev
                                )}
                              </td>
                              <td className="border_right text-center boldTd">
                                {totalRef.current.leftRevContri}%
                              </td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  totalRef.current.rightRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                {totalRef.current.rightRoom > 0 &&
                                totalRef.current.rightRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        totalRef.current.rightRev /
                                          totalRef.current.rightRoom
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  totalRef.current.rightRev
                                )}
                              </td>
                              <td className="border_right text-center boldTd">
                                {totalRef.current.rightRevContri}%
                              </td>
                              <td className="text-center boldTd">
                                {commonService.formateRoom(
                                  totalRef.current.leftRoom -
                                    totalRef.current.rightRoom
                                )}
                              </td>
                              <td className="text-end boldTd">
                                $
                                {totalRef.current.leftRoom > 0 &&
                                totalRef.current.leftRev > 0
                                  ? Math.round(
                                      totalRef.current.leftRev /
                                        totalRef.current.leftRoom
                                    )
                                  : 0 - totalRef.current.rightRoom > 0 &&
                                    totalRef.current.rightRev > 0
                                  ? Math.round(
                                      totalRef.current.rightRev /
                                        totalRef.current.rightRoom
                                    )
                                  : 0}
                              </td>
                              <td className="text-end boldTd">
                                {commonService.formateAmount(
                                  totalRef.current.leftRev -
                                    totalRef.current.rightRev
                                )}
                              </td>
                              <td className="boldTd"></td>
                            </tr>
                          )}
                        </>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      {api !== 0 && (
        <Comments widgetDetails={para} editorId={"NinetyDaychild"} />
      )}

      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
          style={{ zIndex: 999999 }}
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectPickupDate
                  ? selectPickupDate
                  : singleDatePickupDateRef.current
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>
      {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          // widgetId={para?.widgetId}
          widgetId={0}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
          dateForNote={clickedDateForNote.current}
        />
      )}
      {addNoteModal && (
        <AddWidgetModal
          showModal={addNoteModal}
          closeModal={closeNoteModal}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
      {showTicketModal && (
        <EventViewModal
          showModal={showTicketModal}
          closeModal={closeTicketModal}
          eventDetails={eventListPropsRef.current}
          selectedDate={selectedEventDateRef.current}
          getEventList={getEventList}
        />
      )}
      {showToolsSummaryModal && (
        <ToolsSummaryModal
          showToolsSummaryModal={showToolsSummaryModal}
          closeToolsSummaryModal={closeToolsSummaryModal}
          selectedDate={ClickedDate}
          propertyDetail={propertyDetailFromRTK}
        />
      )}
    </div>
  );
};

export default NinentyDayChild;
