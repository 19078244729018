import React, { useEffect, useRef, useState } from "react";
import { apiResponse } from "../../../../utils/apiResponse";
import { apiCall } from "../../../../utils/axiosService";
import { API_URL } from "../../../../utils/apiRoutes";
import { commonService } from "../../../../utils/commonService";
import moment from "moment";
import { useSelector } from "react-redux";
import { Button, Overlay, Popover, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Loader from "../../../Loader/Loader";
import SingleDayGroupModal from "../../SingleDayGroupModal";
import infoIcon from "../../../../Assets/Images/Action Icons/infoIcon..svg";
import NoteIcon from "../../../../Assets/Images/CRM/notes.svg";
import downloadIcon from "../../../../Assets/Images/Operation/downloadIcon.svg";
import WidgetDetailModal from "../../WidgetDetailModal";
import WidgetNote from "../../WidgetNote";
import { compName } from "../../../../utils/constant";
import SingleDateSummaryModal from "../../SingleDateSummaryModal";
import AddWidgetModal from "../../AddWidgetModal";
import { useParams } from "react-router-dom";
import emailIcon from "../../../../Assets/Images/Email.svg";
import { errorToastMessage } from "../../../../utils/toastMessage";
import { FixColumn } from "../../../../utils/dataTable";
import ticket from "../../../../Assets/Images/Revenue-management/tableTicket.svg";
import ToolsSingleTicketPopUp from "../../../CommonComponent/ToolsSingleTicketPopUp";
import EventViewModal from "../../../CommonComponent/EventViewModal";

const SixtyDaySegmentChild = ({ api, para }) => {
  let ooo = 0,
    rmsAvailable = 0,
    leftToSell = 0,
    otb = 0,
    occPercentage = 0,
    oneRms = 0,
    oneAdr = 0,
    oneRev = 0,
    sevenRms = 0,
    sevenAdr = 0,
    sevenRev = 0,
    r28Avg = 0,
    tcyRms = 0,
    tcyAdr = 0,
    tcyRev = 0,
    tlyRms = 0,
    tlyAdr = 0,
    tlyRev = 0,
    gcyBlock = 0,
    gcyRms = 0,
    gcyAdr = 0,
    gcyRev = 0,
    glyRms = 0,
    glyAdr = 0,
    glyRev = 0,
    ccyRms = 0,
    ccyAdr = 0,
    ccyRev = 0,
    clyRms = 0,
    clyAdr = 0,
    clyRev = 0,
    weekDayooo = 0,
    weekDayrmsAvailable = 0,
    weekDayleftToSell = 0,
    weekDayotb = 0,
    weekDayoccPercentage = 0,
    weekDayoneRms = 0,
    weekDayoneAdr = 0,
    weekDayoneRev = 0,
    weekDaysevenRms = 0,
    weekDaysevenAdr = 0,
    weekDaySevenRev = 0,
    weekDayr28Avg = 0,
    weekDaytcyRms = 0,
    weekDaytcyAdr = 0,
    weekDaytcyRev = 0,
    weekDaytlyRms = 0,
    weekDaytlyAdr = 0,
    weekDaytlyRev = 0,
    weekDaygcyBlock = 0,
    weekDaygcyRms = 0,
    weekDaygcyAdr = 0,
    weekDaygcyRev = 0,
    weekDayglyRms = 0,
    weekDayglyAdr = 0,
    weekDayglyRev = 0,
    weekDayccyRms = 0,
    weekDayccyAdr = 0,
    weekDayccyRev = 0,
    weekDayclyRms = 0,
    weekDayclyAdr = 0,
    weekDayclyRev = 0,
    weekEndooo = 0,
    weekEndrmsAvailable = 0,
    weekEndleftToSell = 0,
    weekEndotb = 0,
    weekEndoccPercentage = 0,
    weekEndoneRms = 0,
    weekEndoneAdr = 0,
    weekendoneRev = 0,
    weekEndsevenRms = 0,
    weekEndsevenAdr = 0,
    weekEndSevenRev = 0,
    weekEndr28Avg = 0,
    weekEndtcyRms = 0,
    weekEndtcyAdr = 0,
    weekEndtcyRev = 0,
    weekEndtlyRms = 0,
    weekEndtlyAdr = 0,
    weekEndtlyRev = 0,
    weekEndgcyBlock = 0,
    weekEndgcyRms = 0,
    weekEndgcyAdr = 0,
    weekEndgcyRev = 0,
    weekEndglyRms = 0,
    weekEndglyAdr = 0,
    weekEndglyRev = 0,
    weekEndccyRms = 0,
    weekEndccyAdr = 0,
    weekEndccyRev = 0,
    weekEndclyRms = 0,
    weekEndclyAdr = 0,
    weekEndclyRev = 0,
    dayName = false;
  const userDetail = commonService.getLoginUserData();
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  const startDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const endDate = commonService.getDateInDBFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  );

  const startDateArrayRef = useRef([]);
  const startRangeDateRef = useRef();
  const endRangeDateRef = useRef();
  const dateForNoteRef = useRef();
  const userNoteRef = useRef();
  const maxDateRef = useRef();
  const singleDatePickupDateRef = useRef();
  const singleDateSevenPickupDateRef = useRef();
  const [startRangeDate, setStartRangeDate] = useState();
  const [endRangeDate, setEndRangeDate] = useState();
  const [maxDateForPickupFrom, setMaxDateForPickupFrom] = useState();

  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noteLoading, setNoteLoading] = useState(false);
  const [selectPickupDate, setSelectPickupDate] = useState();
  const [selectSevenPickupDate, setSelectSevenPickupDate] = useState();
  const [pickupFromData, setPickupFromData] = useState(null);
  const [sevenPickupFromData, setSevenPickupFromData] = useState(null);
  const [pickupLoading, setPickupLoading] = useState(false);
  const [sevenPickupLoading, setSevenPickupLoading] = useState(false);
  const [forecastData, setForecastData] = useState(null);
  const [forecastLoading, setForecastLoading] = useState(null);
  const [rateShopListData, setRateShopListData] = useState(null);
  const [rateShopLoading, setRateShopLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const callApi = useRef(false);
  const isFilterApiCalled = useRef(false);
  const [segmentDrilldownData, setSegmentDrilldownData] = useState(null);
  const [dropDownLoading, setDropDownLLoading] = useState(false);
  const rateCodeDataRef = useRef(false);
  const companyDataRef = useRef(false);
  const guestDataRef = useRef(false);
  const totalRef = useRef();
  const clickedTrRef = useRef();
  const [lyData, setLyData] = useState(null);
  const [lyLoading, setLyLoading] = useState(false);
  const [netStlyData, setNetStlyData] = useState(null);
  const [netstlyLoading, setNetstlyLoading] = useState(false);
  const [segmentData, setSegmentData] = useState(null);
  const [segmentLoading, setSegmentLoading] = useState(false);
  const selectedTdIndexRef = useRef();
  const singleDateSelectedDateRef = useRef();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [showNoteSidebar, setShowNoteSidebar] = useState(false);
  const [widgetModal, setWidgetModal] = useState(false);
  const widgetDetailRef = useRef();
  const eventListRef = useRef();
  const eventListPropsRef = useRef();
  const selectedEventDateRef = useRef();
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [ONTHEBOOKS, setONTHEBOOKS] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });
  const closeWidgetModal = () => {
    setWidgetModal(false);
  };
  const closeNoteSideBar = () => {
    setShowNoteSidebar(false);
  };
  const closeTicketModal = () => {
    setShowTicketModal(false);
  };
  const [groupModal, setGroupModal] = useState({
    IsDisplay: false,
    Target: null,
    Position: "top",
  });

  const propertyDetailFromRTK = useSelector(
    (store) => store.headerProperty.propertyDetail
  );
  const defaultAsOfDateFromRTK = useSelector(
    (store) => store.headerProperty.defaultAsOfDate
  );
  const { snapshottitle, propertyCode, snapshotId } = useParams();

  if (maxDateForPickupFrom) {
    let date = new Date(maxDateForPickupFrom);
    maxDateRef.current = date.setDate(date.getDate() - 1);
  }

  const diffDayStartDate = new Date(
    commonService.getDateInFormat(
      startRangeDateRef.current ? startRangeDateRef.current : startDate
    )
  );
  const diffDayEndDate = new Date(
    commonService.getDateInFormat(
      endRangeDateRef.current ? endRangeDateRef.current : endDate
    )
  );
  const diffDayInTime = diffDayEndDate.getTime() - diffDayStartDate.getTime();
  const diffDays = diffDayInTime / (1000 * 3600 * 24);

  const handleDateChange = (event) => {
    const [start, end] = event;
    let startSelectedDate = "",
      endSelectedDate = "";
    if (start) {
      let date = new Date(start);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      startSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      startRangeDateRef.current = startSelectedDate;
    }
    if (end) {
      let date = new Date(end);
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;
      let dateFormat =
        date?.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      endSelectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
      endRangeDateRef.current = endSelectedDate;
    }

    setStartRangeDate(event[0]);
    setEndRangeDate(event[1]);
    if (event[0] !== null && event[1] !== null) {
      //call api when date changes
      // let datesArr = commonService.getDateBetweenTwoDates(
      //   new Date(event[0]),
      //   new Date(event[1])
      // );
      // startDateArrayRef.current = datesArr;
      // getORGDetail();
      // getPickupFromData();
      // getSevenPickupFromData();
      // getForecastDetail();
      // getSegmentDetail();
    }
  };

  const handleFilterBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setONTHEBOOKS({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };
  const closeSingleDate = () => {
    selectedTdIndexRef.current = -1;
    setONTHEBOOKS({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const getEventList = async () => {
    let eventList = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.RevenueManagement + API_URL.EVENTS.GET_LIST,
        body: {
          pageNum: 1,
          pageSize: 10,
          sortColumn: "",
          sortDirection: "asc",
          searchValue: "",
          propertyid: parseInt(propertyDetailFromRTK?.propertyid),
          startdate: null,
          enddate: null,
          eventname: "",
          eventtypeterm: "",
          yearterm: ``,
        },
      },
      false
    );

    let eventListResponse = apiResponse(false, eventList);

    if (eventListResponse?.isValidate) {
      let eventListArr = [];

      eventListResponse?.data?.data?.forEach((item) => {
        let singleEventObj = {
          id: item?.eventid ? item?.eventid : 0,
          name: item?.eventname,
          detail: item?.eventdetails,
          startDate: item?.startdate,
          endDate: item?.enddate,
          eventJson: item?.eventjson,
          eventResourceId: item?.eventsourceid,
        };
        let eventJsonData = "";
        if (item?.eventjson) {
          var decodedString = decodeURIComponent(item?.eventjson);
          const jsonStringWithoutQuotes = decodedString.slice(1, -1);
          const jsonStringWithSingleQuotes = `${jsonStringWithoutQuotes}`;
          eventJsonData = JSON.parse(jsonStringWithSingleQuotes);
          let desc = eventJsonData?.description?.replaceAll("+", " ");
          singleEventObj = {
            ...singleEventObj,
            labels: eventJsonData?.labels ? eventJsonData?.labels : "",
            rank: eventJsonData?.rank ? eventJsonData?.rank : 0,
            local_rank: eventJsonData?.local_rank
              ? eventJsonData?.local_rank
              : 0,
            phq_attendance: eventJsonData?.phq_attendance
              ? eventJsonData?.phq_attendance
              : 0,
            geo_lat: eventJsonData?.geo_lat ? eventJsonData?.geo_lat : "",
            geo_long: eventJsonData?.geo_long ? eventJsonData?.geo_long : "",
            detail: desc,
          };
        }
        eventListArr.push(singleEventObj);
      });
      eventListRef.current = eventListArr;
    }

    if (!eventListResponse?.isValidate) {
      eventListRef.current = [];
    }
  };

  const getORGDetail = async () => {
    setLoading(true);
    await getEventList();

    let orgDetailResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.ORG,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, orgDetailResponse);

    if (response?.isValidate) {
      // startDateArrayRef.current = [];
      // let orgData = response?.data?.data?.map((item, index) => {
      //   let dateForTd = startRangeDate
      //     ? new Date(startRangeDate)
      //     : new Date(startDate);

      //   startDateArrayRef.current = [
      //     ...startDateArrayRef.current,
      //     commonService.getDateInDBFormat(
      //       dateForTd.setDate(dateForTd.getDate() + index)
      //     ),
      //   ];
      //   return item;
      // });
      setOrgData(response?.data?.data);
      setLoading(false);
    }

    if (!response?.isValidate) {
      setLoading(false);
    }
  };

  const getPickupFromData = async () => {
    setPickupLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 1);
    singleDatePickupDateRef.current = pickupDate;
    let pickupFromDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.PICKUP_FROM,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          pickupDate: selectPickupDate
            ? moment(selectPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, pickupFromDataResponse);

    if (response?.isValidate) {
      let pickupFromData = response?.data?.data?.map((item, index) => {
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });

      setPickupFromData(pickupFromData);
      setPickupLoading(false);
    }

    if (!response?.isValidate) {
      setPickupLoading(false);
    }
  };

  const getSevenPickupFromData = async () => {
    setSevenPickupLoading(true);
    let pickupDate = new Date(para?.asOfDate);
    pickupDate.setDate(pickupDate.getDate() - 7);
    singleDateSevenPickupDateRef.current = pickupDate;
    let pickupFromDataResponse = await apiCall(
      {
        method: "POST",
        url: API_URL.BASE_API_URL.SnapShot + API_URL.DAILY_SUMMARY.PICKUP_FROM,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          pickupDate: selectSevenPickupDate
            ? moment(selectSevenPickupDate).format("YYYY-MM-DD")
            : moment(pickupDate).format("YYYY-MM-DD"),
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, pickupFromDataResponse);

    if (response?.isValidate) {
      let pickupFromData = response?.data?.data?.map((item, index) => {
        let dateForTd = startRangeDate
          ? new Date(startRangeDate)
          : new Date(startDate);
        item.date = commonService.getDateInDBFormat(
          dateForTd.setDate(dateForTd.getDate() + index)
        );
        return item;
      });

      setSevenPickupFromData(pickupFromData);
      setSevenPickupLoading(false);
    }

    if (!response?.isValidate) {
      setSevenPickupLoading(false);
    }
  };

  const getForecastDetail = async () => {
    setForecastLoading(true);
    let forecastResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.DAILY_SUMMARY.PRICING_FORECAST,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
          ForcastType: "EMA",
        },
      },
      false
    );

    let response = apiResponse(false, forecastResponse);

    if (response?.isValidate) {
      setForecastData(response?.data?.data);
      setForecastLoading(false);
    }

    if (!response?.isValidate) {
      setForecastData([]);
      setForecastLoading(false);
    }
  };

  const getSegmentDetail = async () => {
    setSegmentLoading(true);
    let segmentDetailResponse = await apiCall(
      {
        method: "POST",
        url:
          API_URL.BASE_API_URL.SnapShot +
          API_URL.REV_PAK_90_DAY_SEGMENT.GET_SEGMENT,
        body: {
          AsOfDate: para?.asOfDate,
          startDate: startRangeDateRef.current
            ? startRangeDateRef.current
            : startDate,
          endDate: endRangeDateRef.current ? endRangeDateRef.current : endDate,
          propertyCode: para?.propertyCode,
        },
      },
      false
    );

    let response = apiResponse(false, segmentDetailResponse);

    if (response?.isValidate) {
      setSegmentData(response?.data?.data);
      setSegmentLoading(false);
    }

    if (!response?.isValidate) {
      setSegmentLoading(false);
    }
  };

  useEffect(() => {
    if (api === 0) {
    } else {
      if (para?.asOfDate) {
        let datesArr = commonService.getDateBetweenTwoDates(
          commonService.convertDateInTimezone(startDate),
          commonService.convertDateInTimezone(endDate)
        );
        startDateArrayRef.current = datesArr;
        setMaxDateForPickupFrom(new Date(para?.defaultAsOfDate));
        getORGDetail();
        getPickupFromData();
        getSevenPickupFromData();
        getForecastDetail();
        getSegmentDetail();
      }
    }
  }, [para?.asOfDate, selectPickupDate, selectSevenPickupDate]);
  const [tblids, settblids] = useState({
    sixtyDaysSegmentTable: commonService.GenerateGUID(),
  });
  commonService.setBackColorPerByTDValue(
    "#638EC6",
    "Red",
    `#${tblids.sixtyDaysSegmentTable} .60DayTable-otb`
  );
  commonService.setBackColorPerByTDValue(
    "#FFB928",
    "Red",
    `#${tblids.sixtyDaysSegmentTable} .60Day-gcyBlock`
  );

  const handleGroupBtnClick = (event) => {
    let Position = "bottom";
    let Top = event.nativeEvent.layerY - 2;
    if (Top + 400 > window.innerHeight) {
      Position = "top";
    }
    setGroupModal({
      IsDisplay: true,
      Target: event.target,
      Position: Position,
    });
  };

  const closeGroupDate = () => {
    selectedTdIndexRef.current = -1;
    setGroupModal({
      IsDisplay: false,
      Target: null,
      Position: "bottom",
    });
  };

  const handleExport = async (tableClassName, type) => {
    let tableId = document.querySelector(`#${tableClassName}`);

    if (tableId.querySelector(".loding-animation")) {
      errorToastMessage("Wait for the data loading.");
      return;
    }

    let groupingJson = {
      data: [],
      filename: `${para?.propertyCode}-DaySegment-${moment(new Date()).format(
        "MMDDYYYY"
      )}`,
    };

    let groupingTable = [
      {
        widgetname: "NinentyDaySegment",
        rowdata: [],
      },
    ];
    let totalJson = [
      {
        widgetname: "BlankCommon",
        rowdata: [],
      },
    ];
    groupingTable[0].rowdata.push({
      data: [para?.rowTitle || ""],
    });
    groupingTable[0].rowdata.push({
      data: [],
    });
    const getTable = document?.querySelector(`#${tableClassName}`);

    const findTblHeader = getTable?.querySelectorAll("thead tr");
    const findTblTbody = getTable?.querySelectorAll("tbody tr");

    findTblHeader?.forEach((tblHeaderItem, tblHeaderIndex) => {
      let allTh = tblHeaderItem.querySelectorAll("th");
      let allThValue = [];
      allTh?.forEach((thItem) => {
        let thSpan = thItem?.querySelector("div>span");
        let thText = thItem?.innerText;
        allThValue.push(thSpan ? thSpan.innerText : thText);
      });

      groupingTable[0].rowdata.push({ data: allThValue });
      if (tableClassName === "thirtyDaySegment" && tblHeaderIndex === 0) {
        groupingTable[0].rowdata.push({ data: [] });
      }

      if (tableClassName === "sixtyDaySegment" && tblHeaderIndex === 0) {
        groupingTable[0].rowdata.push({ data: [] });
      }

      if (tableClassName === "ninentyDaySegment" && tblHeaderIndex === 0) {
        groupingTable[0].rowdata.push({ data: [] });
      }
    });

    findTblTbody?.forEach((tblBodyItem) => {
      let allTd = tblBodyItem.querySelectorAll("td");
      let allTdValue = [],
        totalTdValue = [];

      allTd?.forEach((tdItem, tdIndex) => {
        let tdText = tdItem?.innerText;

        if (
          allTd[0].innerText === "WeekDay" ||
          allTd[0].innerText === "WeekEnd" ||
          allTd[0].innerText === "Total"
        ) {
          totalTdValue.push(tdText || "");
        } else {
          if (tdIndex === 21 || tdIndex === 24) {
            tdText = tdText?.replace("$", "");
          }
          allTdValue.push(tdText || "");
        }
      });
      if (allTdValue?.length > 0) {
        groupingTable[0].rowdata.push({ data: allTdValue });
      }
      if (totalTdValue?.length > 0) {
        totalJson[0].rowdata.push({ data: totalTdValue });
      }
    });
    if (groupingTable[0].rowdata?.length > 0) {
      groupingJson.data = [...groupingJson.data, ...groupingTable];
    }
    if (totalJson[0].rowdata?.length > 0) {
      groupingJson.data = [...groupingJson.data, ...totalJson];
    }
    groupingJson = {
      ...groupingJson,
      snapshotid: snapshotId ? parseInt(snapshotId) : 0,
      sendtoemails: userDetail?.email ? userDetail?.email : "",
      actiontype: type,
      asofdata: para?.asOfDate
        ? commonService.getDateInDBFormat(para?.asOfDate)
        : "",
    };
    await commonService.widgetWiseExportExcel(groupingJson);
  };

  FixColumn(tblids.sixtyDaysSegmentTable, [1]);

  return (
    <div className="mt-3 widget-container" id="sixtyDaySegment">
      <p>60 Days</p>
      {api !== 0 && (
        <div className="gap-1 widget-container-menu">
          {/* <Button className="heading-menu-btns" type="button">
            <img
              src={NoteIcon}
              alt="menuIcon"
              onClick={() => {
                setShowNoteSidebar(true);
              }}
            />
          </Button> */}
          <Button className="heading-menu-btns" type="button" title="Send Mail">
            <img
              src={emailIcon}
              alt="emailIcon"
              onClick={() => {
                if (
                  window.location.href.includes("RevPAK%20Summary") ||
                  window.location.href.includes("90%20Day%20Segment")
                ) {
                  handleExport("sixtyDaySegment", "EMAIL");
                } else {
                  commonService.fnSendWidgetMail(
                    tblids.sixtyDaysSegmentTable,
                    "90 Days Segment",
                    snapshotId,
                    defaultAsOfDateFromRTK,
                    "",
                    para?.widgetId
                  );
                }
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={infoIcon}
              alt="info"
              onClick={() => {
                widgetDetailRef.current = {
                  widgetdetails: "",
                  usernote: "",
                  compName: compName?.ninetyDaySegment,
                  widgetId: para?.widgetId,
                };
                setWidgetModal(true);
              }}
            />
          </Button>
          <Button className="heading-menu-btns" type="button">
            <img
              src={downloadIcon}
              alt="menuIcon"
              import={tblids.sixtyDaysSegmentTable}
              onClick={() => {
                if (
                  window.location.href.includes("RevPAK%20Summary") ||
                  window.location.href.includes("90%20Day%20Segment")
                ) {
                  handleExport("sixtyDaySegment", "DOWNLOAD");
                } else {
                  commonService.fnExportInCSV(
                    tblids.sixtyDaysSegmentTable,
                    // "90 Days Segment"
                    `${para?.propertyCode}-90 Days Segment-${moment(
                      new Date()
                    ).format("MMDDYYYY")}`
                  );
                }
              }}
            />
          </Button>
        </div>
      )}
      <div className="ViewPropertySetupTable">
        <div className="stripped__table">
          <div className="table-responsive tblDynamicPickupSticky">
            <Table
              id={tblids.sixtyDaysSegmentTable}
              className="table-with-spacing positionStaticTable"
            >
              <thead className="tbl-header-sticky">
                <tr className="table-main-header-tr">
                  <th className="border_right"></th>
                  <th className="border_right text-center" colSpan={4}>
                    On The Books
                  </th>
                  <th colSpan={2} className="border_right width100">
                    <div className="">
                      <span>1 Day Pickup</span>
                      <DatePicker
                        className=""
                        id="pickupDate"
                        maxDate={new Date(maxDateRef.current)}
                        selected={
                          selectPickupDate
                            ? commonService.convertDateInTimezone(
                                selectPickupDate
                              )
                            : commonService.convertDateInTimezone(
                                maxDateRef.current
                              )
                        }
                        onChange={(date) => {
                          let month =
                            date.getMonth() + 1 > 9
                              ? date.getMonth() + 1
                              : `0${date.getMonth() + 1}`;
                          let dateFormat =
                            date?.getDate() > 9
                              ? date.getDate()
                              : `0${date.getDate()}`;
                          let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                          singleDatePickupDateRef.current = selectedDate;
                          setSelectPickupDate(selectedDate);
                        }}
                      />
                      <span style={{ display: "none" }}>
                        {selectPickupDate
                          ? commonService.getDateInFormat(
                              new Date(selectPickupDate)
                            )
                          : commonService.getDateInFormat(
                              new Date(maxDateRef.current)
                            )}
                      </span>
                    </div>
                  </th>
                  <th colSpan={2} className="border_right width100">
                    <div className="">
                      <span>7 Day Pickup</span>
                      <DatePicker
                        className=""
                        id="pickupDate"
                        maxDate={new Date(maxDateRef.current)}
                        selected={
                          selectSevenPickupDate
                            ? commonService.convertDateInTimezone(
                                selectSevenPickupDate
                              )
                            : new Date(maxDateRef.current).setDate(
                                new Date(maxDateRef.current).getDate() - 7
                              )
                        }
                        onChange={(date) => {
                          let month =
                            date.getMonth() + 1 > 9
                              ? date.getMonth() + 1
                              : `0${date.getMonth() + 1}`;
                          let dateFormat =
                            date?.getDate() > 9
                              ? date.getDate()
                              : `0${date.getDate()}`;
                          let selectedDate = `${date.getFullYear()}-${month}-${dateFormat}`;
                          singleDateSevenPickupDateRef.current = selectedDate;
                          setSelectSevenPickupDate(selectedDate);
                        }}
                      />
                      <span style={{ display: "none" }}>
                        {selectSevenPickupDate
                          ? commonService.getDateInFormat(
                              new Date(selectSevenPickupDate)
                            )
                          : commonService.getDateInFormat(
                              new Date(maxDateRef.current).setDate(
                                new Date(maxDateRef.current).getDate() - 7
                              )
                            )}
                      </span>
                    </div>
                  </th>
                  <th className="text-center border_right">R28 Avg</th>
                  <th className="text-center border_right" colSpan={3}>
                    Transient CY
                  </th>
                  <th className="text-center border_right" colSpan={3}>
                    Transient LY
                  </th>
                  <th className="text-center border_right" colSpan={4}>
                    Group CY
                  </th>
                  <th className="text-center border_right" colSpan={3}>
                    Group LY
                  </th>
                  <th className="text-center border_right" colSpan={3}>
                    Contract CY
                  </th>
                  <th className="text-center" colSpan={3}>
                    Contract LY
                  </th>
                </tr>
                <tr className="table-main-header-tr">
                  <th
                    className="width175 border_right"
                    onClick={() => {
                      setOpenDatePicker(true);
                    }}
                  >
                    Date
                    <DatePicker
                      onChange={(event) => handleDateChange(event)}
                      startDate={startRangeDate}
                      endDate={endRangeDate}
                      selectsRange
                      monthsShown={2}
                      open={openDatePicker}
                      shouldCloseOnSelect={false}
                    >
                      <div
                        className="w-100"
                        style={{ display: "inline-block" }}
                      >
                        <div className="content-inside-calendar">
                          <p>
                            {startRangeDate
                              ? commonService.getDateInFormat(startRangeDate)
                              : ""}
                            {startRangeDate && endRangeDate ? " - " : ""}
                            {endRangeDate
                              ? commonService.getDateInFormat(endRangeDate)
                              : ""}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <button
                              className="secondary-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setStartRangeDate("");
                                setEndRangeDate("");
                                setOpenDatePicker(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                startRangeDate && endRangeDate ? false : true
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  startRangeDateRef.current &&
                                  endRangeDateRef.current
                                ) {
                                  let datesArr =
                                    commonService.getDateBetweenTwoDates(
                                      commonService.convertDateInTimezone(
                                        startRangeDateRef.current
                                      ),
                                      commonService.convertDateInTimezone(
                                        endRangeDateRef.current
                                      )
                                    );
                                  startDateArrayRef.current = datesArr;
                                  getORGDetail();
                                  getPickupFromData();
                                  getSevenPickupFromData();
                                  getForecastDetail();
                                  getSegmentDetail();
                                }

                                setOpenDatePicker(false);
                              }}
                              className="primary-btn"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </DatePicker>
                    {startRangeDate && endRangeDate ? (
                      <span
                        style={{ display: "none" }}
                      >{`${commonService.getDateInFormat(
                        startRangeDate
                      )} - ${commonService.getDateInFormat(
                        endRangeDate
                      )}`}</span>
                    ) : (
                      ""
                    )}
                  </th>
                  <th className="width75 text-center">OOO\RMS Available</th>
                  <th className="width75 text-center">Left To Sell</th>
                  <th className="width75 text-center">On The Books</th>
                  <th className="width75 text-center border_right">
                    Total OCC Percentage
                  </th>
                  <th className="text-center">Rooms</th>
                  <th className="text-end border_right">ADR</th>
                  <th className="text-center">Rooms</th>
                  <th className="text-end border_right">ADR</th>
                  <th className="border_right"></th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end border_right">REV</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end border_right">REV</th>
                  <th className="text-center">Block</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end border_right">REV</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end border_right">REV</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end border_right">REV</th>
                  <th className="text-center">RMS</th>
                  <th className="text-end">ADR</th>
                  <th className="text-end">REV</th>
                </tr>
              </thead>
              <tbody>
                {loading === true ||
                pickupLoading === true ||
                sevenPickupLoading === true ||
                forecastLoading === true ? (
                  <tr style={{ height: "80px", position: "relative" }}>
                    <Loader />
                  </tr>
                ) : (
                  // orgData &&
                  // orgData?.map((item, index) => {
                  startDateArrayRef.current?.map((item, index) => {
                    dayName = false;
                    if (
                      moment(
                        new Date(startDateArrayRef.current[index]),
                        "l"
                      ).format("ddd") === "Fri" ||
                      moment(
                        new Date(startDateArrayRef.current[index]),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      dayName = true;
                    }

                    let singleOTBData = orgData?.filter(
                      (x) =>
                        moment(x.Date).format("YYYY-MM-DD") ===
                        moment(startDateArrayRef.current[index]).format(
                          "YYYY-MM-DD"
                        )
                    );

                    let onePickupData = pickupFromData?.filter(
                      (x) =>
                        moment(x.Dates).format("YYYY-MM-DD") ===
                        moment(startDateArrayRef.current[index]).format(
                          "YYYY-MM-DD"
                        )
                    );

                    let sevenPickupData = sevenPickupFromData?.filter(
                      (x) =>
                        moment(x.Dates).format("YYYY-MM-DD") ===
                        moment(startDateArrayRef.current[index]).format(
                          "YYYY-MM-DD"
                        )
                    );

                    let singleForecastData = forecastData?.filter(
                      (x) =>
                        moment(x.Dates).format("YYYY-MM-DD") ===
                        moment(startDateArrayRef.current[index]).format(
                          "YYYY-MM-DD"
                        )
                    );

                    let singleSegmentData = segmentData?.filter(
                      (x) =>
                        moment(x?.Dates).format("YYYY-MM-DD") ===
                        moment(startDateArrayRef.current[index]).format(
                          "YYYY-MM-DD"
                        )
                    );

                    ooo =
                      ooo +
                      (singleOTBData?.[0]?.OOO ? singleOTBData?.[0]?.OOO : 0);

                    rmsAvailable =
                      rmsAvailable +
                      (singleOTBData?.[0]?.RoomAvailable
                        ? singleOTBData?.[0]?.RoomAvailable
                        : 0);

                    leftToSell =
                      leftToSell +
                      (singleOTBData?.[0]?.LeftToSell
                        ? singleOTBData?.[0]?.LeftToSell
                        : 0);

                    otb =
                      otb +
                      (singleOTBData?.[0]?.OnTheBook
                        ? singleOTBData?.[0]?.OnTheBook
                        : 0);

                    occPercentage =
                      occPercentage +
                      (singleOTBData?.[0]?.TotalOCCPercentage
                        ? singleOTBData?.[0]?.TotalOCCPercentage
                        : 0);

                    oneRms =
                      oneRms +
                      (onePickupData?.[0]?.RMS ? onePickupData?.[0]?.RMS : 0);

                    oneAdr =
                      oneAdr +
                      (onePickupData?.[0]?.ADR ? onePickupData?.[0]?.ADR : 0);

                    oneRev =
                      oneRev +
                      (onePickupData?.[0]?.REV ? onePickupData?.[0]?.REV : 0);
                    sevenRev =
                      sevenRev +
                      (sevenPickupData?.[0]?.REV
                        ? sevenPickupData?.[0]?.REV
                        : 0);

                    sevenRms =
                      sevenRms +
                      (sevenPickupData?.[0]?.RMS
                        ? sevenPickupData?.[0]?.RMS
                        : 0);
                    sevenAdr =
                      sevenAdr +
                      (sevenPickupData?.[0]?.ADR
                        ? sevenPickupData?.[0]?.ADR
                        : 0);

                    r28Avg =
                      r28Avg +
                      (singleForecastData?.[0]?.R28AVG
                        ? singleForecastData?.[0]?.R28AVG
                        : 0);

                    tcyRms =
                      tcyRms +
                      (singleSegmentData?.[0]?.Transient_CY_RMS
                        ? singleSegmentData?.[0]?.Transient_CY_RMS
                        : 0);

                    tcyRev =
                      tcyRev +
                      (singleSegmentData?.[0]?.Transient_CY_REV
                        ? singleSegmentData?.[0]?.Transient_CY_REV
                        : 0);

                    tlyRms =
                      tlyRms +
                      (singleSegmentData?.[0]?.Transient_LY_RMS
                        ? singleSegmentData?.[0]?.Transient_LY_RMS
                        : 0);
                    tlyRev =
                      tlyRev +
                      (singleSegmentData?.[0]?.Transient_LY_REV
                        ? singleSegmentData?.[0]?.Transient_LY_REV
                        : 0);
                    gcyBlock =
                      gcyBlock +
                      (singleSegmentData?.[0]?.Group_CY_Block
                        ? singleSegmentData?.[0]?.Group_CY_Block
                        : 0);
                    gcyRms =
                      gcyRms +
                      (singleSegmentData?.[0]?.Group_CY_RMS
                        ? singleSegmentData?.[0]?.Group_CY_RMS
                        : 0);
                    gcyRev =
                      gcyRev +
                      (singleSegmentData?.[0]?.Group_CY_REV
                        ? singleSegmentData?.[0]?.Group_CY_REV
                        : 0);
                    glyRms =
                      glyRms +
                      (singleSegmentData?.[0]?.Group_LY_RMS
                        ? singleSegmentData?.[0]?.Group_LY_RMS
                        : 0);
                    glyRev =
                      glyRev +
                      (singleSegmentData?.[0]?.Group_LY_REV
                        ? singleSegmentData?.[0]?.Group_LY_REV
                        : 0);
                    ccyRms =
                      ccyRms +
                      (singleSegmentData?.[0]?.Contract_CY_RMS
                        ? singleSegmentData?.[0]?.Contract_CY_RMS
                        : 0);
                    ccyRev =
                      ccyRev +
                      (singleSegmentData?.[0]?.Contract_CY_REV
                        ? singleSegmentData?.[0]?.Contract_CY_REV
                        : 0);
                    clyRms =
                      clyRms +
                      (singleSegmentData?.[0]?.Contract_LY_RMS
                        ? singleSegmentData?.[0]?.Contract_LY_RMS
                        : 0);
                    clyRev =
                      clyRev +
                      (singleSegmentData?.[0]?.Contract_LY_REV
                        ? singleSegmentData?.[0]?.Contract_LY_REV
                        : 0);

                    if (
                      moment(
                        new Date(startDateArrayRef.current[index]),
                        "l"
                      ).format("ddd") === "Sun" ||
                      moment(
                        new Date(startDateArrayRef.current[index]),
                        "l"
                      ).format("ddd") === "Sat"
                    ) {
                      weekEndooo =
                        weekEndooo +
                        (singleOTBData?.[0]?.OOO ? singleOTBData?.[0]?.OOO : 0);

                      weekEndrmsAvailable =
                        weekEndrmsAvailable +
                        (singleOTBData?.[0]?.RoomAvailable
                          ? singleOTBData?.[0]?.RoomAvailable
                          : 0);

                      weekEndleftToSell =
                        weekEndleftToSell +
                        (singleOTBData?.[0]?.LeftToSell
                          ? singleOTBData?.[0]?.LeftToSell
                          : 0);

                      weekEndotb =
                        weekEndotb +
                        (singleOTBData?.[0]?.OnTheBook
                          ? singleOTBData?.[0]?.OnTheBook
                          : 0);

                      weekEndoccPercentage =
                        weekEndoccPercentage +
                        (singleOTBData?.[0]?.TotalOCCPercentage
                          ? singleOTBData?.[0]?.TotalOCCPercentage
                          : 0);

                      weekEndoneRms =
                        weekEndoneRms +
                        (onePickupData?.[0]?.RMS ? onePickupData?.[0]?.RMS : 0);

                      weekEndoneAdr =
                        weekEndoneAdr +
                        (onePickupData?.[0]?.ADR ? onePickupData?.[0]?.ADR : 0);

                      weekendoneRev =
                        weekendoneRev +
                        (onePickupData?.[0]?.REV ? onePickupData?.[0]?.REV : 0);
                      weekEndSevenRev =
                        weekEndSevenRev +
                        (sevenPickupData?.[0]?.REV
                          ? sevenPickupData?.[0]?.REV
                          : 0);

                      weekEndsevenRms =
                        weekEndsevenRms +
                        (sevenPickupData?.[0]?.RMS
                          ? sevenPickupData?.[0]?.RMS
                          : 0);
                      weekEndsevenAdr =
                        weekEndsevenAdr +
                        (sevenPickupData?.[0]?.ADR
                          ? sevenPickupData?.[0]?.ADR
                          : 0);

                      weekEndr28Avg =
                        weekEndr28Avg +
                        (singleForecastData?.[0]?.R28AVG
                          ? singleForecastData?.[0]?.R28AVG
                          : 0);

                      weekEndtcyRms =
                        weekEndtcyRms +
                        (singleSegmentData?.[0]?.Transient_CY_RMS
                          ? singleSegmentData?.[0]?.Transient_CY_RMS
                          : 0);

                      weekEndtcyRev =
                        weekEndtcyRev +
                        (singleSegmentData?.[0]?.Transient_CY_REV
                          ? singleSegmentData?.[0]?.Transient_CY_REV
                          : 0);

                      weekEndtlyRms =
                        weekEndtlyRms +
                        (singleSegmentData?.[0]?.Transient_LY_RMS
                          ? singleSegmentData?.[0]?.Transient_LY_RMS
                          : 0);
                      weekEndtlyRev =
                        weekEndtlyRev +
                        (singleSegmentData?.[0]?.Transient_LY_REV
                          ? singleSegmentData?.[0]?.Transient_LY_REV
                          : 0);
                      weekEndgcyBlock =
                        weekEndgcyBlock +
                        (singleSegmentData?.[0]?.Group_CY_Block
                          ? singleSegmentData?.[0]?.Group_CY_Block
                          : 0);
                      weekEndgcyRms =
                        weekEndgcyRms +
                        (singleSegmentData?.[0]?.Group_CY_RMS
                          ? singleSegmentData?.[0]?.Group_CY_RMS
                          : 0);
                      weekEndgcyRev =
                        weekEndgcyRev +
                        (singleSegmentData?.[0]?.Group_CY_REV
                          ? singleSegmentData?.[0]?.Group_CY_REV
                          : 0);
                      weekEndglyRms =
                        weekEndglyRms +
                        (singleSegmentData?.[0]?.Group_LY_RMS
                          ? singleSegmentData?.[0]?.Group_LY_RMS
                          : 0);
                      weekEndglyRev =
                        weekEndglyRev +
                        (singleSegmentData?.[0]?.Group_LY_REV
                          ? singleSegmentData?.[0]?.Group_LY_REV
                          : 0);
                      weekEndccyRms =
                        weekEndccyRms +
                        (singleSegmentData?.[0]?.Contract_CY_RMS
                          ? singleSegmentData?.[0]?.Contract_CY_RMS
                          : 0);
                      weekEndccyRev =
                        weekEndccyRev +
                        (singleSegmentData?.[0]?.Contract_CY_REV
                          ? singleSegmentData?.[0]?.Contract_CY_REV
                          : 0);
                      weekEndclyRms =
                        weekEndclyRms +
                        (singleSegmentData?.[0]?.Contract_LY_RMS
                          ? singleSegmentData?.[0]?.Contract_LY_RMS
                          : 0);
                      weekEndclyRev =
                        weekEndclyRev +
                        (singleSegmentData?.[0]?.Contract_LY_REV
                          ? singleSegmentData?.[0]?.Contract_LY_REV
                          : 0);
                    } else {
                      weekDayooo =
                        weekDayooo +
                        (singleOTBData?.[0]?.OOO ? singleOTBData?.[0]?.OOO : 0);

                      weekDayrmsAvailable =
                        weekDayrmsAvailable +
                        (singleOTBData?.[0]?.RoomAvailable
                          ? singleOTBData?.[0]?.RoomAvailable
                          : 0);

                      weekDayleftToSell =
                        weekDayleftToSell +
                        (singleOTBData?.[0]?.LeftToSell
                          ? singleOTBData?.[0]?.LeftToSell
                          : 0);

                      weekDayotb =
                        weekDayotb +
                        (singleOTBData?.[0]?.OnTheBook
                          ? singleOTBData?.[0]?.OnTheBook
                          : 0);

                      weekDayoccPercentage =
                        weekDayoccPercentage +
                        (singleOTBData?.[0]?.TotalOCCPercentage
                          ? singleOTBData?.[0]?.TotalOCCPercentage
                          : 0);

                      weekDayoneRms =
                        weekDayoneRms +
                        (onePickupData?.[0]?.RMS ? onePickupData?.[0]?.RMS : 0);

                      weekDayoneAdr =
                        weekDayoneAdr +
                        (onePickupData?.[0]?.ADR ? onePickupData?.[0]?.ADR : 0);
                      weekDayoneRev =
                        weekDayoneRev +
                        (onePickupData?.[0]?.REV ? onePickupData?.[0]?.REV : 0);
                      weekDaySevenRev =
                        weekDaySevenRev +
                        (sevenPickupData?.[0]?.REV
                          ? sevenPickupData?.[0]?.REV
                          : 0);

                      weekDaysevenRms =
                        weekDaysevenRms +
                        (sevenPickupData?.[0]?.RMS
                          ? sevenPickupData?.[0]?.RMS
                          : 0);
                      weekDaysevenAdr =
                        weekDaysevenAdr +
                        (sevenPickupData?.[0]?.ADR
                          ? sevenPickupData?.[0]?.ADR
                          : 0);

                      weekDayr28Avg =
                        weekDayr28Avg +
                        (singleForecastData?.[0]?.R28AVG
                          ? singleForecastData?.[0]?.R28AVG
                          : 0);

                      weekDaytcyRms =
                        weekDaytcyRms +
                        (singleSegmentData?.[0]?.Transient_CY_RMS
                          ? singleSegmentData?.[0]?.Transient_CY_RMS
                          : 0);

                      weekDaytcyRev =
                        weekDaytcyRev +
                        (singleSegmentData?.[0]?.Transient_CY_REV
                          ? singleSegmentData?.[0]?.Transient_CY_REV
                          : 0);

                      weekDaytlyRms =
                        weekDaytlyRms +
                        (singleSegmentData?.[0]?.Transient_LY_RMS
                          ? singleSegmentData?.[0]?.Transient_LY_RMS
                          : 0);
                      weekDaytlyRev =
                        weekDaytlyRev +
                        (singleSegmentData?.[0]?.Transient_LY_REV
                          ? singleSegmentData?.[0]?.Transient_LY_REV
                          : 0);
                      weekDaygcyBlock =
                        weekDaygcyBlock +
                        (singleSegmentData?.[0]?.Group_CY_Block
                          ? singleSegmentData?.[0]?.Group_CY_Block
                          : 0);
                      weekDaygcyRms =
                        weekDaygcyRms +
                        (singleSegmentData?.[0]?.Group_CY_RMS
                          ? singleSegmentData?.[0]?.Group_CY_RMS
                          : 0);
                      weekDaygcyRev =
                        weekDaygcyRev +
                        (singleSegmentData?.[0]?.Group_CY_REV
                          ? singleSegmentData?.[0]?.Group_CY_REV
                          : 0);
                      weekDayglyRms =
                        weekDayglyRms +
                        (singleSegmentData?.[0]?.Group_LY_RMS
                          ? singleSegmentData?.[0]?.Group_LY_RMS
                          : 0);
                      weekDayglyRev =
                        weekDayglyRev +
                        (singleSegmentData?.[0]?.Group_LY_REV
                          ? singleSegmentData?.[0]?.Group_LY_REV
                          : 0);
                      weekDayccyRms =
                        weekDayccyRms +
                        (singleSegmentData?.[0]?.Contract_CY_RMS
                          ? singleSegmentData?.[0]?.Contract_CY_RMS
                          : 0);
                      weekDayccyRev =
                        weekDayccyRev +
                        (singleSegmentData?.[0]?.Contract_CY_REV
                          ? singleSegmentData?.[0]?.Contract_CY_REV
                          : 0);
                      weekDayclyRms =
                        weekDayclyRms +
                        (singleSegmentData?.[0]?.Contract_LY_RMS
                          ? singleSegmentData?.[0]?.Contract_LY_RMS
                          : 0);
                      weekDayclyRev =
                        weekDayclyRev +
                        (singleSegmentData?.[0]?.Contract_LY_REV
                          ? singleSegmentData?.[0]?.Contract_LY_REV
                          : 0);
                    }

                    let checkEvent = eventListRef.current?.filter((x) => {
                      let checkingDate = moment(item);
                      let startingDate = moment(x?.startDate);
                      let endingDate = moment(x?.endDate);
                      if (
                        checkingDate.isSameOrAfter(startingDate) &&
                        checkingDate.isSameOrBefore(endingDate)
                      ) {
                        return x;
                      }
                    });

                    return (
                      <React.Fragment key={index}>
                        <tr
                          className={`${
                            dayName ? "backgroundTr" : ""
                          } shadowOnHover`}
                        >
                          <td className="border_right">
                            <div className="d-flex gap-4">
                              {commonService.getDateInFormat(
                                startDateArrayRef.current[index]
                              )}{" "}
                              {moment(
                                new Date(startDateArrayRef.current[index]),
                                "l"
                              ).format("ddd")}
                              <div className="d-flex justify-content-between align-items-center gap-2 ticketAlarmReload">
                                {checkEvent?.length > 0 && (
                                  <img
                                    style={{ height: "11px", width: "11px" }}
                                    className="iconcolor"
                                    src={ticket}
                                    alt="ticket"
                                    onClick={() => {
                                      selectedEventDateRef.current =
                                        startDateArrayRef.current[index];
                                      eventListPropsRef.current = checkEvent;
                                      setShowTicketModal(true);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.OOO
                              ? commonService.formateRoom(singleOTBData?.[0]?.OOO)
                              : 0}{" "}
                            \{" "}
                            {singleOTBData?.[0]?.RoomAvailable
                              ? commonService.formateRoom(singleOTBData?.[0]?.RoomAvailable)
                              : 0}
                            {/* {`${
                            singleOTBData?.[0]?.OOO
                              ? singleOTBData?.[0]?.OOO
                              : 0
                          } / ${
                            singleOTBData?.[0]?.RoomAvailable
                              ? singleOTBData?.[0]?.RoomAvailable
                              : 0
                          }`} */}
                          </td>
                          <td className="text-center">
                            {singleOTBData?.[0]?.LeftToSell
                              ? commonService.formateRoom(singleOTBData?.[0]?.LeftToSell)
                              : 0}
                          </td>
                          <td
                            className="text-primary text-center perbackground 60DayTable-otb"
                            value={singleOTBData?.[0]?.OnTheBook}
                          >
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                singleDateSelectedDateRef.current = item;
                                handleFilterBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleOTBData?.[0]?.OnTheBook
                                ? commonService.formateRoom(singleOTBData?.[0]?.OnTheBook)
                                : 0}
                            </span>
                          </td>

                          <td className="text-center border_right">
                            {singleOTBData?.[0]?.TotalOCCPercentage
                              ? singleOTBData?.[0]?.TotalOCCPercentage
                              : 0}
                            %
                          </td>
                          <td className="text-center">
                            {onePickupData?.[0]?.RMS
                              ? commonService.formateRoom(onePickupData?.[0]?.RMS)
                              : 0}
                          </td>
                          <td className="text-end border_right">
                            {onePickupData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  onePickupData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-center">
                            {sevenPickupData?.[0]?.RMS
                              ? commonService.formateRoom(sevenPickupData?.[0]?.RMS)
                              : 0}
                          </td>
                          <td className="text-end border_right">
                            {sevenPickupData?.[0]?.ADR
                              ? commonService.formateAmount(
                                  sevenPickupData?.[0]?.ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-center border_right">
                            {singleForecastData?.[0]?.R28AVG
                              ? commonService.formateRoom(singleForecastData?.[0]?.R28AVG)
                              : 0}
                          </td>
                          <td className="text-center">
                            {singleSegmentData?.[0]?.Transient_CY_RMS
                              ? commonService.formateRoom(singleSegmentData?.[0]?.Transient_CY_RMS)
                              : 0}
                          </td>
                          <td className="text-end">
                            {singleSegmentData?.[0]?.Transient_CY_ADR
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Transient_CY_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {singleSegmentData?.[0]?.Transient_CY_REV
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Transient_CY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          <td className="text-center">
                            {singleSegmentData?.[0]?.Transient_LY_RMS
                              ? commonService.formateRoom(singleSegmentData?.[0]?.Transient_LY_RMS)
                              : 0}
                          </td>
                          <td className="text-end">
                            {singleSegmentData?.[0]?.Transient_LY_ADR
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Transient_LY_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {singleSegmentData?.[0]?.Transient_LY_REV
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Transient_LY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          <td
                            className="text-center text-primary perbackground 60Day-gcyBlock"
                            value={singleSegmentData?.[0]?.Group_CY_Block}
                          >
                            {/* {singleSegmentData?.[0]?.Group_CY_Block
                            ? singleSegmentData?.[0]?.Group_CY_Block
                            : 0} */}
                            <span
                              className={`${
                                selectedTdIndexRef.current === index
                                  ? "singleDateSummarySpan"
                                  : ""
                              }`}
                              onClick={(e) => {
                                singleDateSelectedDateRef.current =
                                  startDateArrayRef.current[index];
                                handleGroupBtnClick(e);
                                selectedTdIndexRef.current = index;
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {singleSegmentData?.[0]?.Group_CY_Block
                                ? commonService.formateRoom(singleSegmentData?.[0]?.Group_CY_Block)
                                : 0}
                            </span>
                          </td>
                          <td className="text-center">
                            {singleSegmentData?.[0]?.Group_CY_RMS
                              ? commonService.formateRoom(singleSegmentData?.[0]?.Group_CY_RMS)
                              : 0}
                          </td>
                          <td className="text-end">
                            {singleSegmentData?.[0]?.Group_CY_ADR
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Group_CY_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {singleSegmentData?.[0]?.Group_CY_REV
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Group_CY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          <td className="text-center">
                            {singleSegmentData?.[0]?.Group_LY_RMS
                              ? commonService.formateRoom(singleSegmentData?.[0]?.Group_LY_RMS)
                              : 0}
                          </td>
                          <td className="text-end">
                            {singleSegmentData?.[0]?.Group_LY_ADR
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Group_LY_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {singleSegmentData?.[0]?.Group_LY_REV
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Group_LY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          <td className="text-center">
                            {singleSegmentData?.[0]?.Contract_CY_RMS
                              ? commonService.formateRoom(singleSegmentData?.[0]?.Contract_CY_RMS)
                              : 0}
                          </td>
                          <td className="text-end">
                            {singleSegmentData?.[0]?.Contract_CY_ADR
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Contract_CY_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end border_right">
                            {singleSegmentData?.[0]?.Contract_CY_REV
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Contract_CY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>

                          <td className="text-center">
                            {singleSegmentData?.[0]?.Contract_LY_RMS
                              ? commonService.formateRoom(singleSegmentData?.[0]?.Contract_LY_RMS)
                              : 0}
                          </td>
                          <td className="text-end">
                            {singleSegmentData?.[0]?.Contract_LY_ADR
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Contract_LY_ADR
                                )
                              : commonService.formateAmount(0)}
                          </td>
                          <td className="text-end">
                            {singleSegmentData?.[0]?.Contract_LY_REV
                              ? commonService.formateAmount(
                                  singleSegmentData?.[0]?.Contract_LY_REV
                                )
                              : commonService.formateAmount(0)}
                          </td>
                        </tr>
                        {index === startDateArrayRef.current?.length - 1 && (
                          <>
                            {/* WEEK DAY TOTAL */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">WeekDay</td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDayooo)} \ {commonService.formateRoom(weekDayrmsAvailable)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDayleftToSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDayotb)}
                              </td>
                              <td className="boldTd text-center border_right">
                                {weekDayoccPercentage > 0
                                  ? `${parseInt(
                                      weekDayoccPercentage / diffDays
                                    )}%`
                                  : `0%`}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDayoneRms)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {weekDayoneRev > 0 && weekDayoneRms > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekDayoneRev / weekDayoneRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDaysevenRms)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {weekDaySevenRev > 0 && weekDaysevenRms > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekDaySevenRev / weekDaysevenRms
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-center border_right">
                                {commonService.formateRoom(weekDayr28Avg)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDaytcyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDaytcyRms > 0 && weekDaytcyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekDaytcyRev / weekDaytcyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekDaytcyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDaytlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDaytlyRms > 0 && weekDaytlyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekDaytlyRev / weekDaytlyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekDaytlyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {weekDaygcyBlock}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDaygcyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDaygcyRms > 0 && weekDaygcyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekDaygcyRev / weekDaygcyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekDaygcyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDayglyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDayglyRms > 0 && weekDayglyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekDayglyRev / weekDayglyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekDayglyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDayccyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDayccyRms > 0 && weekDayccyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekDayccyRev / weekDayccyRms)
                                    )
                                  : 0}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekDayccyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekDayclyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekDayclyRms > 0 && weekDayclyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekDayclyRev / weekDayclyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(weekDayclyRev)}
                              </td>
                            </tr>
                            {/* WEEK END TOTAL */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">WeekEnd</td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndooo)} \ {commonService.formateRoom(weekEndrmsAvailable)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndleftToSell)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndotb)}
                              </td>
                              <td className="boldTd text-center border_right">
                                {weekEndoccPercentage > 0
                                  ? `${parseInt(
                                      weekEndoccPercentage / diffDays
                                    )}%`
                                  : `0%`}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndoneRms)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {weekendoneRev > 0 && weekEndoneRms > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekendoneRev / weekEndoneRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndsevenRms)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {weekEndSevenRev > 0 && weekEndsevenRms > 0
                                  ? commonService.formateAmount(
                                      Math.round(
                                        weekEndSevenRev / weekEndsevenRms
                                      )
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-center border_right">
                                {weekEndr28Avg}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndtcyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEndtcyRms > 0 && weekEndtcyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekEndtcyRev / weekEndtcyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekEndtcyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndtlyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEndtlyRms > 0 && weekEndtlyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekEndtlyRev / weekEndtlyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekEndtlyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {weekEndgcyBlock}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndgcyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEndgcyRms > 0 && weekEndgcyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekEndgcyRev / weekEndgcyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekEndgcyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndglyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEndglyRms > 0 && weekEndglyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekEndglyRev / weekEndglyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekEndglyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndccyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEndccyRms > 0 && weekEndccyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekEndccyRev / weekEndccyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(weekEndccyRev)}
                              </td>

                              <td className="boldTd text-center">
                                {commonService.formateRoom(weekEndclyRms)}
                              </td>
                              <td className="boldTd text-end">
                                {weekEndclyRms > 0 && weekEndclyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(weekEndclyRev / weekEndclyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(weekEndclyRev)}
                              </td>
                            </tr>
                            {/* TOTAL */}
                            <tr className="shadowOnHover">
                              <td className="boldTd border_right">Total</td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(ooo)} \ {commonService.formateRoom(rmsAvailable)}
                              </td>
                              <td className="boldTd text-center">
                                {commonService.formateRoom(leftToSell)}
                              </td>
                              <td className="boldTd text-center">{commonService.formateRoom(otb)}</td>
                              <td className="boldTd text-center border_right">
                                {occPercentage > 0
                                  ? `${parseInt(occPercentage / diffDays)}%`
                                  : `0%`}
                              </td>
                              <td className="boldTd text-center">{commonService.formateRoom(oneRms)}</td>
                              <td className="boldTd text-end border_right">
                                {oneRev > 0 && oneRms > 0
                                  ? commonService.formateAmount(
                                      Math.round(oneRev / oneRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-center">{commonService.formateRoom(sevenRms)}</td>
                              <td className="boldTd text-end border_right">
                                {sevenRev > 0 && sevenRms > 0
                                  ? commonService.formateAmount(
                                      Math.round(sevenRev / sevenRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-center border_right">
                                {r28Avg}
                              </td>
                              <td className="boldTd text-center">{commonService.formateRoom(tcyRms)}</td>
                              <td className="boldTd text-end">
                                {tcyRms > 0 && tcyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(tcyRev / tcyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(tcyRev)}
                              </td>

                              <td className="boldTd text-center">{commonService.formateRoom(tlyRms)}</td>
                              <td className="boldTd text-end">
                                {tlyRms > 0 && tlyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(tlyRev / tlyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(tlyRev)}
                              </td>

                              <td className="boldTd text-center">{gcyBlock}</td>
                              <td className="boldTd text-center">{commonService.formateRoom(gcyRms)}</td>
                              <td className="boldTd text-end">
                                {gcyRms > 0 && gcyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(gcyRev / gcyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(gcyRev)}
                              </td>

                              <td className="boldTd text-center">{commonService.formateRoom(glyRms)}</td>
                              <td className="boldTd text-end">
                                {glyRms > 0 && glyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(glyRev / glyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(glyRev)}
                              </td>

                              <td className="boldTd text-center">{commonService.formateRoom(ccyRms)}</td>
                              <td className="boldTd text-end">
                                {ccyRms > 0 && ccyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(ccyRev / ccyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end border_right">
                                {commonService.formateAmount(ccyRev)}
                              </td>

                              <td className="boldTd text-center">{commonService.formateRoom(clyRms)}</td>
                              <td className="boldTd text-end">
                                {clyRms > 0 && clyRev > 0
                                  ? commonService.formateAmount(
                                      Math.round(clyRev / clyRms)
                                    )
                                  : commonService.formateAmount(0)}
                              </td>
                              <td className="boldTd text-end">
                                {commonService.formateAmount(clyRev)}
                              </td>
                            </tr>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
        {showTicketModal && (
          <EventViewModal
            showModal={showTicketModal}
            closeModal={closeTicketModal}
            eventDetails={eventListPropsRef.current}
            selectedDate={selectedEventDateRef.current}
            getEventList={getEventList}
          />
        )}
      </div>
      <Overlay
        show={groupModal.IsDisplay}
        target={groupModal.Target}
        placement={groupModal.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
        >
          <div className="horizontal-menu-option active singleDayGroupSummary">
            <SingleDayGroupModal
              closeModal={closeGroupDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              propertyCode={para?.propertyCode}
            />
          </div>
        </Popover>
      </Overlay>
      <Overlay
        show={ONTHEBOOKS.IsDisplay}
        target={ONTHEBOOKS.Target}
        placement={ONTHEBOOKS.Position}
      >
        <Popover
          id="popover-contained"
          className="SingleDateSummaryModalPopoverMain"
          style={{ zIndex: 999999 }}
        >
          <div className="horizontal-menu-option active">
            <SingleDateSummaryModal
              closeModal={closeSingleDate}
              asOfDate={para?.asOfDate}
              selectedDate={singleDateSelectedDateRef.current}
              pickupDate={
                selectPickupDate
                  ? selectPickupDate
                  : singleDatePickupDateRef.current
              }
              propertyDetail={{ propertyCode: para?.propertyCode }}
            />
          </div>
        </Popover>
      </Overlay>
      {/* {showNoteSidebar && (
        <WidgetNote
          show={showNoteSidebar}
          handleClose={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )} */}
      {showNoteSidebar && (
        <AddWidgetModal
          showModal={showNoteSidebar}
          closeModal={closeNoteSideBar}
          widgetId={para?.widgetId}
          propertyId={propertyDetailFromRTK?.propertyid}
          rowTitle={para?.rowTitle}
        />
      )}
      {widgetModal && (
        <WidgetDetailModal
          showModal={widgetModal}
          closeModal={closeWidgetModal}
          data={widgetDetailRef.current}
        />
      )}
    </div>
  );
};

export default SixtyDaySegmentChild;
